import { Grid, Link, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppRoutes } from '../../app/routes';
import { SubPageWrapper } from '../../components/postLogin/SubPageWrapper';



export const CallUs: React.FC = () => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles(){
    return makeStyles({
      buttonsWrapper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '315px'
        }
      },
      lowKeyLink: {
        color: `${theme.palette.common.black}!important`,
        fontWeight: 'bold'
      }
    })();
  }

  return (
    <SubPageWrapper to={AppRoutes.HELP}>
      <Grid item className={style.buttonsWrapper}>
        <Typography variant="h1">
          Call Us
        </Typography>
        <Typography variant="body1">
          <Link className={style.lowKeyLink} href="tel:8012573577">(801) 257-3577</Link>
        </Typography>
        <Typography variant="body1">
          Monday - Friday, 9 a.m. - 5 p.m. MST
        </Typography>
      </Grid>
    </SubPageWrapper>
  );
};