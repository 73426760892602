import { useTheme, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppRoutes } from '../../app/routes';
import headerLogo from '../../assets/sie-logo-red.svg';

interface PreLoginHeaderProps extends RouteComponentProps {
  location: any
}

const PreLoginHeader: React.FC<PreLoginHeaderProps> = ({ location }) => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles() {
    return makeStyles({
      root: {
        position: 'relative',
        top: '0',
        left: '0',
        width: '100vw',
        padding: '36px 38px',
        [theme.breakpoints.up('md')]: {
          position: 'absolute',
        }
      },
      headerLogo: {
        height: '20px',
        [theme.breakpoints.up('md')]: {
          height: '32px'
        }
      }
    })();
  }

  const noNavbarRoutes = [
    AppRoutes.HOME,
    AppRoutes.ACCOUNT,
    AppRoutes.ABOUT,
    AppRoutes.SPONSORS,
    AppRoutes.HELP,
    AppRoutes.CALL,
    AppRoutes.MESSAGE,
    AppRoutes.MSG_SENT,
    AppRoutes.FAQ,
  ];

  // render empty element if location path should not show the nav bar
  if (!location || noNavbarRoutes.includes(location.pathname as AppRoutes)) {
    return <></>;
  }

  return (
    <Grid item container xs={12} className={style.root}>
      <img className={style.headerLogo} src={headerLogo} />
    </Grid>
  );
};

export default withRouter(PreLoginHeader);
