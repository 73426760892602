import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, useTheme } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { AppRoutes } from '../../app/routes';
import { SubPageWrapper } from '../../components/postLogin/SubPageWrapper';



export const FAQs: React.FC = () => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles(){
    return makeStyles({
      buttonsWrapper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '315px'
        }
      }
    })();
  }

  return (
    <SubPageWrapper to={AppRoutes.HELP}>
      <Grid item className={style.buttonsWrapper}>
        <Typography variant="h1">
          FAQs
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="body1" style={{margin: 0}}>
              The sponsor did not accept my coupon.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              If a sponsor does not accept your coupon offer
              please use the Message Us functionality to contact
              our team. Let us know which coupon you tried to
              redeem, and the location. We will work to contact
              our sponsor about this issue.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="body1" style={{margin: 0}}>
              I redeemed a coupon by mistake.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Please use the Message Us functionality to contact
              our team. We will work with you to get the coupon
              loaded back into your account.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="body1" style={{margin: 0}}>
              I am a faculty or staff member but did not receive
              an email invitation to sign-up.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              The Faculty and Staff program is only available to
              school personnel who clock 25 hours a week or more.
              If this is you, and you did not receive the sign-up
              email please use the Message Us functionality to
              contact our team for registration information.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="body1" style={{margin: 0}}>
              My login is not working.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              If you are having a hard time logging into your Fac &
              Staff account, please use the Message Us functionality
              to contact our team for further assistance.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </SubPageWrapper>
  );
};