import { useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { Validator } from '../../app/validators';

export interface TextField {
  id: string,
  type?: string,
  variant?: string,
  label: string,
  autoComplete?: string,
  validators?: Validator[],
}
interface InputTypes {
  [key: string]: any;
}

const useInput = () => {
  const dispatch = useAppDispatch();
  const [inputValues, setInputValues] = useState<InputTypes>({});
  const [inputErrors, setInputErrors] = useState<InputTypes>({});
  const [disabled, setDisabled] = useState<boolean>(true);

  const validateAll = (fields: TextField[]) => {
    var validFlag = true;
    fields.forEach(field => {
      if(!validate(field.id, field.validators))
        validFlag = false;
    });
    return validFlag;
  };

  const setError = (id: string, msg: string) => {
    setInputErrors((inputErrors) => ({
      ...inputErrors,
      [id]: msg,
    }));
  };

  const clearError = (id: string) => {
    setInputErrors((inputErrors) => ({
      ...inputErrors,
      [id]: '',
    }));
  };

  const setValue = (id: string, msg: string) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      [id]: msg,
    }));
  };

  const clearValue = (id: string) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      [id]: '',
    }));
  };

  const errorsExist = () => {
    var flag = false;
    Object.keys(inputErrors).forEach((key: string) => {
      if(inputErrors[key] && inputErrors[key].length > 0){
        flag = true;
      }
    });
    return flag;
  };

  const isDisabled = (disabledByDefault?: boolean) => {
    if(disabledByDefault)
      return disabled || errorsExist();
    else
      return errorsExist();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, formatter?: (value: string) => string) => {
    const eventID = event.target.id;
    event.target.value = formatter ? formatter(event.target.value) : event.target.value;
    const eventValue = event.target.value;
    setValue(eventID, eventValue);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const eventID = event.target.id;
    const eventValue = event.target.value;
    setValue(eventID, eventValue);
  };

  const validate = (id: string, validators?: Validator[]) => {
    if(disabled){
      setDisabled(false);
    }

    var validFlag = true;
    var value = inputValues[id];
    validators?.every(validator => {
      var response = validator.f(value);
      if(!response){
        setError(id, validator.errMsg);
        validFlag = false;
        return false;
      }
      else{
        clearError(id);
      }
    });

    return validFlag;
  };


  return {
    handleInputChange,
    handleSelectChange,
    validate,
    errorsExist,
    isDisabled,
    setError,
    validateAll,
    inputValues,
    inputErrors
  };
};

export default useInput;
