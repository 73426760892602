import { TextField, Typography, useTheme, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { AppRoutes } from '../app/routes';
import { RootState } from '../app/store';
import { PageWrapper } from '../components/postLogin/PageWrapper';
import useInput from '../features/inputs/useInput';
import { fetchCurrentUser, updateCurrentUser } from '../features/user/data/user.thunks';
import { UserRoles } from '../features/user/data/user.types';

const AccountInformation: React.FC<PropsFromRedux> = ({ user }) => {
  const {
    handleInputChange,
    inputValues
  } = useInput();
  const theme = useTheme();
  const style = useStyles();
  const dispatch = useAppDispatch();
  const [saved, setSaved] = useState<boolean>(false);

  function useStyles(){
    return makeStyles({
      root: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '315px'
        }
      }
    })();
  }

  function resetPasswordAdornment(){
    return <Link to={AppRoutes.FORGOT_PASSWORD}>Edit</Link>;
  }

  function saveChanges(){
    if(user){
      dispatch(updateCurrentUser({
        FirstName: inputValues['FirstName'] || user.firstName,
        LastName: inputValues['LastName'] || user.lastName
      })).then(res => {
        if(res.meta.requestStatus == 'fulfilled'){
          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 3000);
          dispatch(fetchCurrentUser());
        }
      });
    }
  }

  return (
    <PageWrapper>
      <Grid item className={style.root}>
        <Typography variant='h1'>
          My Account
        </Typography>
        <TextField
          id="FirstName"
          label='First Name'
          variant='standard'
          defaultValue={ user?.firstName }
          onChange={handleInputChange}
        >
        </TextField>
        <TextField
          id="LastName"
          label='Last Name'
          variant='standard'
          defaultValue={ user?.lastName }
          onChange={handleInputChange}
        >
        </TextField>
        <TextField
          label='School'
          variant='standard'
          defaultValue={ user?.school.name }
          InputProps={{ readOnly: true }}
        >
        </TextField>
        <TextField
          label='Program'
          variant='standard'
          defaultValue={ user?.roles.includes(UserRoles.KTSUser) ? 'KTS' : 'RTS' }
          InputProps={{ readOnly: true }}
        >
        </TextField>
        <TextField
          label='Email'
          variant='standard'
          defaultValue={ user?.email }
          InputProps={{ readOnly: true }}
        >
        </TextField>
        <TextField
          label='Password'
          type='password'
          variant='standard'
          defaultValue='1234567890'
          InputProps={{ endAdornment: resetPasswordAdornment(), readOnly: true }}
        >
        </TextField>
        {
          saved
            ?
            <Button variant='outlined' style={{ marginTop: '20px' }} disabled>SAVED!</Button>
            :
            <Button variant='contained' style={{ marginTop: '20px' }} onClick={saveChanges}>Save</Button>
        }
      </Grid>
    </PageWrapper>
  );
};

function mapStateToProps(state: RootState) {
  return {
    user: state.user.user || undefined
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AccountInformation);