import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { fetchCurrentUser, signin, signup, messageUs } from './user.thunks';
import { UserState } from './user.types';

export const initialState: UserState = {
  user: undefined,
  loading: false,
  attemptedFetch: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
        state.attemptedFetch = true;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.attemptedFetch = true;
      })
      .addCase(signin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
        state.attemptedFetch = true;
      })
      .addCase(signin.rejected, (state, action) => {
        state.loading = false;
        state.attemptedFetch = true;
      })
      .addCase(signup.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
        state.attemptedFetch = true;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.attemptedFetch = true;
      })
      .addCase(messageUs.fulfilled, (state, action) => {
        state.loading = false;
        state.attemptedFetch = true;
      })
      .addCase(messageUs.rejected, (state, action)=>{
        state.loading = false;
        state.attemptedFetch = true;
      });
  },
});

// Selectors
export const selectCurrentUser = (state: RootState) => state.user.user;
export const selectUserLoading = (state: RootState) => state.user.loading;
export const selectAttemptedToFetchUser = (state: RootState) => state.user.attemptedFetch;

export default userSlice.reducer;
