import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MouseEvent, useEffect, useState } from 'react';
import { Config } from '../../app/config';
import Coupon, { LocationData } from './data/coupon.model';
import { useAppDispatch } from '../../app/hooks';
import { redeemCoupon } from './data/coupon.thunk';


interface CouponDetailsProps {
  coupon: Coupon,
  onRedeem: (activeLocation?: LocationData) => void
}

export const CouponDetails: React.FC<CouponDetailsProps> = ({ coupon, onRedeem }) => {
  const theme = useTheme();
  const style = createStyles();
  const dispatch = useAppDispatch();
  const [resourceUrl, setResourceUrl] = useState<string>('');
  const [activeLocation, setActiveLocation] = useState<LocationData | undefined>(undefined);

  useEffect(() => {

    async function getResourceUrl() {
      let url = await Config.getResourceUrl();
      setResourceUrl(url);
    }

    getResourceUrl();

  }, []);

  function createStyles(){
    return makeStyles({
      outerBox: {
        [theme.breakpoints.up('sm')]: {
          maxWidth: '450px',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          padding: '50px'
        }
      },
      couponLogo: {
        width: '100%',
        margin: '30px 0 30px 0!important',
        [theme.breakpoints.up('sm')]: {
          margin: '0 0 30px 0!important'
        }
      },
      couponName: {
        width: '100%',
        textAlign: 'center',
        margin: '0 0 10px 0!important'
      },
      couponLocationOffer: {
        width: '100%',
        textAlign: 'center',
        margin: '0 0 10px 0!important'
      },
      couponLocationName: {
        width: '100%',
        textAlign: 'center',
        margin: '0 0 10px 0!important'
      },
      couponDescription: {
        width: '100%',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: 'Barlow',
        fontSize: '16px',
        margin: '0 0 30px 0!important'
      },
      redemptionAdvisory: {
        width: '100%',
        textAlign: 'center',
        margin: '0 0 30px 0!important'
      },
      redeemButton: {
        margin: '0 0 10px 0!important',
      },
      redeemedLabel: {
        width: '100%',
        textAlign: 'center',
        color: '#FF463A',
        margin: '0 0 30px 0!important'
      },
      expirationLabel: {
        width: '100%',
        textAlign: 'center',
        color: '#FF463A'
      },
      locationTile: {
        width: '100%',
        margin: '0 0 20px 0!important',
        padding: '20px 0!important',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
      },
      oneTimeUse: {
        width: '100%',
        textAlign: 'center',
        margin: '0 0 30px 0!important'
      }
    })();
  }

  function selectLocation(location: LocationData){
    setActiveLocation(location);
  }

  function redeem(){
    dispatch(redeemCoupon({coupon, loc: activeLocation || {name: '', value: '', text: ''}}))
      .then(response => {
        if(response.meta.requestStatus == 'fulfilled')
          onRedeem(activeLocation);
        else
          alert('Something went wrong while redeeming your coupon');
      });
  }

  if(coupon.redeemed){
    return (
      <Grid item container className={style.outerBox}>
        <img className={style.couponLogo} src={`${resourceUrl}${coupon?.logo}`} />
        <Typography variant="h3" className={style.couponName}>
          { coupon?.name }
        </Typography>
        <Typography variant="h3" className={style.redeemedLabel}>
          <b>REDEEMED</b>
        </Typography>
        <Typography variant="body1" className={style.couponDescription}>
          { coupon?.description }
        </Typography>
        <Typography variant="body1" className={style.expirationLabel}>
          <b>Expires { `${new Date(coupon?.expiresAt || '0').getMonth() + 1}/${new Date(coupon?.expiresAt || '0').getDate()}/${new Date(coupon?.expiresAt || '0').getFullYear()}` }</b>
        </Typography>
      </Grid>
    );
  }
  else if(activeLocation){
    return (
      <Grid item container className={style.outerBox}>
        <img className={style.couponLogo} src={`${resourceUrl}${coupon?.logo}`} />
        <Typography variant="h3" className={style.couponLocationOffer}>
          { activeLocation.value }
        </Typography>
        <Typography variant="body1" className={style.couponLocationName}>
          { activeLocation.name }
        </Typography>
        <Typography variant="body1" className={style.oneTimeUse}>
          One time use
        </Typography>
        <Button variant="contained" className={style.redeemButton} onClick={(event: MouseEvent<HTMLButtonElement>) => redeem()}>
          REDEEM
        </Button>
        <Typography variant="body2" className={style.redemptionAdvisory}>
          Once redeemed, coupon will be disabled
        </Typography>
        <Typography variant="body1" className={style.couponDescription}>
          { activeLocation.text }
        </Typography>
        <Typography variant="body1" className={style.expirationLabel}>
          <b>Expires { `${new Date(coupon?.expiresAt || '0').getMonth() + 1}/${new Date(coupon?.expiresAt || '0').getDate()}/${new Date(coupon?.expiresAt || '0').getFullYear()}` }</b>
        </Typography>
      </Grid>
    );
  }
  else if(coupon?.locations?.length > 0 || 0){
    return (
      <Grid item container className={style.outerBox}>
        <img className={style.couponLogo} src={`${resourceUrl}${coupon?.logo}`} />
        {
          coupon.locations.map((location, index) => {
            return (
              <Grid item container key={index} className={style.locationTile} onClick={() => selectLocation(location)}>
                <Typography variant="body1" style={{margin: '0!important'}}>
                  <b>{ location.name }</b>
                </Typography>
              </Grid>
            );
          })
        }
        <Typography variant="body2" className={style.redemptionAdvisory}>
          Only one coupon can be redeemed
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item container className={style.outerBox}>
      <img className={style.couponLogo} src={`${resourceUrl}${coupon?.logo}`} />
      <Typography variant="h3" className={style.couponName}>
        { coupon?.name }
      </Typography>
      {
        coupon.redemptionsAllowed == 1
          ?
          <Typography variant="body1" className={style.oneTimeUse}>
            One time use
          </Typography>
          : <></>
      }
      <Button variant="contained" className={style.redeemButton} onClick={(event: MouseEvent<HTMLButtonElement>) => redeem()}>
        REDEEM
      </Button>
      <Typography variant="body2" className={style.redemptionAdvisory}>
        Once redeemed, coupon will be disabled
      </Typography>
      <Typography variant="body1" className={style.couponDescription}>
        { coupon?.description }
      </Typography>
      {
        coupon.mustPresentIdCard
          ?
          <Typography variant="body1" className={style.oneTimeUse}>
            <b>Must present School ID Card</b>
          </Typography>
          : <></>
      }
      <Typography variant="body1" className={style.expirationLabel}>
        <b>Expires { `${new Date(coupon?.expiresAt || '0').getMonth() + 1}/${new Date(coupon?.expiresAt || '0').getDate()}/${new Date(coupon?.expiresAt || '0').getFullYear()}` }</b>
      </Typography>
    </Grid>
  );
};