import settings from './settings';
import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/Button' {
  interface ButtonPropsVariantOverrides{
    tile: true
  }
}

const variants = createTheme({
  ...settings,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          marginBottom: '20px',
          overflow: 'visible',
          boxShadow: 'none',
          height: '60px',
          width: '100%',
          padding: '10px 20px',
          '&:focus': {
            boxShadow: 'none'
          },
          [settings.breakpoints.up('sm')]: {
            padding: '10px 40px'
          }
        },
        label: {
          fontFamily: '"Inter", sans-serif',
          fontSize: '16px',
          letterSpacing: '1px',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: settings.palette.primary.main,
            color: settings.palette.primary.contrastText,
            borderRadius: '30px',
            boxShadow: 'none',
            '&:focus': {
              backgroundColor: settings.palette.primary.main
            },
            '&:active': {
              boxShadow: 'inherit'
            }
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: 'transparent',
            color: settings.palette.primary.main,
            border: `1px solid ${settings.palette.primary.main}`,
            borderRadius: '30px',
            boxShadow: 'none',
            '&:focus': {

            },
            '&:active': {
              boxShadow: 'inherit'
            }
          }
        },
        {
          props: { variant: 'text' },
          style: {
            backgroundColor: 'transparent',
            color: settings.palette.common.black,
            boxShadow: 'none',
            textTransform: 'none',
            fontWeight: 400,
            width: 'fit-content',
            '&:focus': {
            },
            '&:active': {
              boxShadow: 'inherit'
            }
          }
        },
        {
          props: { variant: 'tile' },
          style: {
            backgroundColor: 'transparent',
            color: settings.palette.common.black,
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.4)!important',
            textTransform: 'none',
            fontWeight: 600,
            padding: '0.5em 1.5em!important',
            justifyContent: 'space-between',
            width: '100%',
          }
        }
      ]
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: '0px',
          padding: '10px 0'
        }
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: '100%',
          borderWidth: '0 0 1px 0'
        }
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: '2em'
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          margin: '0'
        }
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '10px 0'
        }
      },
    },
    MuiLink: {
      variants: [
        {
          props: { variant: 'body2' },
          style: {
            color: settings.palette.secondary.contrastText,
            fontFamily: 'inherit',
            fontSize: '1em',
            fontWeight: 'normal',
            '&:hover': {
              textDecoration: 'none',
              cursor: 'pointer'
            }
          }
        }
      ]
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '0',
          marginBottom: '7px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '10px',
          margin: '0'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: settings.palette.common.black,
          fontSize: '16px'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: '10px 20px',
          [settings.breakpoints.up('sm')]:{
            padding: '20px 40px'
          },
          [settings.breakpoints.up('md')]:{
            border: '1px solid #D6D6D6',
            borderWidth: '0 0 1px 0'
          },
          [settings.breakpoints.up('lg')]:{
            padding: '20px 200px'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '0!important',
          paddingRight: '0!important'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          marginBottom: '16px'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#E2E7FA',
          boxShadow: 'none',
          borderRadius: '7px',
          minHeight: '3em!important'
        },
        content: {
          margin: '1em 0!important'
        }
      }
    }
  },
});

export default variants;