import { Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PageWrapper } from '../components/postLogin/PageWrapper';
import arbys from '../assets/sponsors/arbys.png';
import chuckarama from '../assets/sponsors/chuckarama.png';
import fatcats from '../assets/sponsors/fatcats.png';
import flowrider from '../assets/sponsors/flowrider.png';
import intellibed from '../assets/sponsors/intellibed.png';
import irock from '../assets/sponsors/irock.png';
import ivory from '../assets/sponsors/ivory.png';
import kengarff from '../assets/sponsors/kengarff.png';
import macu from '../assets/sponsors/macu.png';
import marketstreetgrill from '../assets/sponsors/marketstreetgrill.png';
import masterpieceimages from '../assets/sponsors/masterpieceimages.png';
import paulmitchell from '../assets/sponsors/paulmitchell.png';
import tuacahn from '../assets/sponsors/tuacahn.png';
import weekender from '../assets/sponsors/weekender.png';
import zionsbank from '../assets/sponsors/zionsbank.png';
import spaghetti from '../assets/sponsors/spaghettifactory.png';
import swig from '../assets/sponsors/swig.png';
import mobettahs from '../assets/sponsors/mobettahs.png';
import rnr from '../assets/sponsors/rnrbbq.png';
import limone from '../assets/sponsors/limone.jpg';
import symphony from '../assets/sponsors/utahsymphony.png';
import opera from '../assets/sponsors/utahopera.png';
import { useState } from 'react';
import { useAppSelector } from '../app/hooks';
import { UserRoles } from '../features/user/data/user.types';



export const Sponsors: React.FC = () => {
  const theme = useTheme();
  const style = useStyles();
  const user = useAppSelector(state => state.user);
  const KTSUsers = {
    bigLogos: [
      macu,
      ivory,
      kengarff,
    ],
    otherLogos: [
      arbys,
      chuckarama,
      fatcats,
      flowrider,
      intellibed,
      irock,
      marketstreetgrill,
      masterpieceimages,
      paulmitchell,
      tuacahn,
      weekender,
      spaghetti,
      swig,
      mobettahs,
      rnr,
      limone,
      symphony,
      opera
    ]
  };
  const RTSUsers = {
    bigLogos: [
      zionsbank,
      ivory,
      kengarff,
    ],
    otherLogos: [
      arbys,
      chuckarama,
      fatcats,
      flowrider,
      intellibed,
      irock,
      marketstreetgrill,
      masterpieceimages,
      paulmitchell,
      tuacahn,
      weekender,
      spaghetti,
      swig,
      mobettahs,
      rnr,
      limone,
      symphony,
      opera
    ]
  };
  const [currentSponsors, setCurrentSponsors] = useState<{bigLogos: string[], otherLogos: string[]}>(user.user?.roles.includes(UserRoles.RTSUser) ? RTSUsers : KTSUsers);

  function useStyles(){
    return makeStyles({
      sponsorLogosWrapper: {
        display: 'flex',
        flexDirection: 'column'
      },
      logosWrapper: {
        display: 'block',
      },
      bigLogo: {
        maxWidth: '100%',
        maxHeight: '200px',
        margin: '0 0 50px 0',
        [theme.breakpoints.up('md')]: {
          maxWidth: '42%',
          maxHeight: '150px',
          margin: '30px'
        }
      },
      smallLogo: {
        margin: '0 10px 30px 0',
        width: '45%',
        [theme.breakpoints.up('md')]: {
          width: '10%',
          margin: '10px',
        }
      }
    })();
  }

  return (
    <PageWrapper>
      <Typography variant='h1'>
        Sponsors
      </Typography>
      <Grid item className={style.sponsorLogosWrapper}>
        <Grid item className={style.logosWrapper}>
          {
            currentSponsors.bigLogos.map((logo, index) => {
              return (
                <img className={style.bigLogo} key={index} src={logo} />
              );
            })
          }
        </Grid>
        <Grid item className={style.logosWrapper}>
          {
            currentSponsors.otherLogos.map((logo, index) => {
              return (
                <img className={style.smallLogo} key={index} src={logo} />
              );
            })
          }
        </Grid>
      </Grid>
    </PageWrapper>
  );
};