export enum AppRoutes {
  ROOT = '/',
  HOME = '/home',
  SIGN_IN = '/signin',
  SIGN_UP = '/signup',
  FORGOT_PASSWORD = '/forgot-password',
  CREATE_PASSWORD = '/create-password',
  ACCOUNT = '/account',
  ABOUT = '/about',
  SPONSORS = '/sponsors',
  HELP = '/help',
  CALL = '/call-us',
  FAQ = '/faqs',
  MESSAGE = '/message-us',
  MSG_SENT = '/message-confirmation'
}
