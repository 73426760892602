import { useTheme, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import splashScreen from '../../assets/splash-screen.svg';

interface PreLoginBannerProps{
  displayOverride?: boolean
}

const PreLoginBanner : React.FC<PreLoginBannerProps> = (props: PreLoginBannerProps) => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles() {
    return makeStyles({
      root: {
        boxSizing: 'border-box',
        display: 'none!important',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '25px 25px 50px 25px',
        backgroundColor: 'white',
        [theme.breakpoints.up('md')]: {
          display: 'flex!important',
          padding: '0 100px',
          backgroundColor: theme.palette.secondary.light,
        }
      },
      innerContent: {
        maxWidth: '500px'
      },
      splashScreen: {
        width: '150px',
        [theme.breakpoints.up('sm')]: {
          width: '200px'
        },
        [theme.breakpoints.up('md')]: {
          width: '100%',
          maxWidth: '400px'
        }
      },
      display: {
        display: 'flex!important'
      }
    })();
  };

  return (
    <Grid item container xs={12} md={6} className={`${style.root} ${props.displayOverride ? style.display : ''}`}>
      <Grid item className={style.innerContent}>
        <img className={style.splashScreen} src={splashScreen} />
        <Typography variant="h1" style={{ color: theme.palette.secondary.main }}>Success in Education<br/>Faculty & Staff Coupons</Typography>
        <Typography variant="body1">Sign in or create an account using your school email address to access your coupons.</Typography>
      </Grid>
    </Grid>
  );
};



export default PreLoginBanner;