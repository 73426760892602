import { Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


export const PageWrapper: React.FC = (props) => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles(){
    return makeStyles({
      root: {
        width: '100%',
        padding: '25px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 300px)',
        [theme.breakpoints.up('md')]: {
          padding: '30px 200px 100px 200px'
        }
      }
    })();
  }

  return (
    <Grid className={style.root} item container>
      { props.children }
    </Grid>
  );
};