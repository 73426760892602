export const phoneFormatter = (phone: string) => {
  const phoneArr = phone.replace(/[^0-9]/g, '').split('');

  const newPhoneArr = phoneArr.map((el, i) => {
    if ( i === 2 || i === 5) {
      return el + '-';
    }
    return el;
  });

  return newPhoneArr.join('').substr(0,12);
};