import { Button, Grid, Typography, useTheme, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PageWrapper } from '../../components/postLogin/PageWrapper';
import question from '../../assets/icons/question.svg';
import mail from '../../assets/icons/mail.svg';
import phone from '../../assets/icons/phone.svg';
import { AppRoutes } from '../../app/routes';



export const HelpCenter: React.FC = () => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles(){
    return makeStyles({
      buttonsWrapper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '315px'
        }
      },
      buttonText: {
        width: '90%',
        flexGrow: 0,
        textAlign: 'left',
        paddingLeft: '10px'
      }
    })();
  }

  return (
    <PageWrapper>
      <Grid item className={style.buttonsWrapper}>
        <Typography variant='h1'>
          Help Center
        </Typography>
        <Button
          variant='tile'
          startIcon={<img src={question} />}
          href={AppRoutes.FAQ}
        >
          <Box className={style.buttonText}>FAQs</Box>
        </Button>
        <Button
          variant='tile'
          startIcon={<img src={mail} />}
          href={AppRoutes.MESSAGE}
        >
          <Box className={style.buttonText}>Message Us</Box>
        </Button>
        <Button
          variant='tile'
          startIcon={<img src={phone} />}
          href={AppRoutes.CALL}
        >
          <Box className={style.buttonText}>Call Us</Box>
        </Button>
      </Grid>
    </PageWrapper>
  );
};