import { Typography, useTheme, Box, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PageWrapper } from '../components/postLogin/PageWrapper';
import rtsLogo from '../assets/rts-logo.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { UserRoles } from '../features/user/data/user.types';



export const About: React.FC = () => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles(){
    return makeStyles({
      root: {

      },
      textEm: {
        fontStyle: 'italic',
        fontWeight: 600
      },
      centerContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '100px 0 0 0'
      },
      rtsBox: {
        width: '100%',
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%'
      }
    })();
  }

  function isRTSUser(){
    var roles = useSelector((state: RootState) => state.user.user?.roles);
    return roles?.includes(UserRoles.RTSUser);
  }

  return (
    <PageWrapper>
      <Typography variant='h1'>
        About
      </Typography>
      <Typography variant='body1'>
        In partnership with incredible sponsors,&nbsp;
        <Box component='span' className={style.textEm}>Success in Education</Box> offers a yearly coupon booklet for
        faculty and staff of Utah secondary schools that participate in Success in Education’s two school-based
        programs:&nbsp;
        <Box component='span' className={style.textEm}>Keys to Success</Box> and&nbsp;
        <Box component='span' className={style.textEm}>Road to Success</Box>. These coupons are a way to show
        faculty and staff how much we appreciate what you are doing in educating the next generation of Utah
        students.
      </Typography>
      <Typography variant='body1'>
        Faculty and Staff Coupons have always been in a coupon booklet format in years past. Due to the incredible
        adoption of Success in Education programs throughout the state, we have switched to a digital format to be
        more accessible and reduce our carbon footprint.
      </Typography>
      <Typography variant='body2' style={{marginTop: '40px'}}>
        <b>Note:</b> Each coupon is single use unless otherwise noted. Your school ID must be presented with your
        coupon, which can be shown to the vendor on your phone or printed out.
      </Typography>
      {
        isRTSUser()
          ?
          <Grid container item className={style.centerContent}>
            <Grid item className={style.rtsBox}>
              <img src={rtsLogo} alt="RTS logo" />
              <br/>
              <Typography variant="body1">
                <b>Don&apos;t have a RTS account? Enroll your school now</b>
              </Typography>
              <br/>
              <Button
                variant="outlined"
                href="https://www.rtsutah.org/enroll-your-school/"
                target="_blank"
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
          :
          <></>
      }

    </PageWrapper>
  );
};