import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import CouponTile from '../features/coupons/CouponTile';
import Coupon from '../features/coupons/data/coupon.model';

interface MasonryTile {
  colSpan: number,
  rowSpan: number,
  contents: Coupon
}

interface MasonryBreakpoint {
  [screenWidth: number]: number
}

interface MasonryProps {
  onTileClick: (coupon: Coupon) => void,
  containerWidth: number,
  items: MasonryTile[],
  breakpoints: MasonryBreakpoint[]
}


/*

  step 1: place largest object in first available spot

  step 2: iterate on step 1 until all objects have been placed

    2a: if there aren't two empty rows at the bottom of the layout, add rows (assumes our biggest tile spans only two rows)

  step 3: perform swaps

*/

export const Masonry: React.FC<MasonryProps> = ({ onTileClick, containerWidth, items, breakpoints }) => {
  const [masonryLayout, setLayout] = useState<number[][]>([]);
  const numRows = masonryLayout.length;
  const numCols = masonryLayout[0]?.length;
  const cellDim = containerWidth / numCols;
  const tilePadding = 15;

  // create masonry layout
  useEffect(() => {
    if(containerWidth == 0) return;

    // helper functions
    const emptyRow = (cols: number) => {
      var row = [];
      for(var i = 0; i < cols; i++){
        row.push(0);
      }
      return row;
    };

    const addEmptyRow = (cols: number) => {
      layout.push(emptyRow(cols));
    };

    const generateCells = (yCoord: number, xCoord: number, item: MasonryTile) => {
      var cells = [];
      for(var i = 0; i < item.rowSpan; i++){
        for(var j = 0; j < item.colSpan; j++){
          cells.push({yCoord: i + yCoord, xCoord: j + xCoord});
        }
      }
      return cells;
    };

    const checkAvailability = (cells: { yCoord: number, xCoord: number }[]) => {
      for(var i = 0; i < cells.length; i++){
        var { yCoord, xCoord } = cells[i];
        if(xCoord >= layout[yCoord].length)
          return false;
        if(yCoord >= layout.length)
          return false;
        if(layout[yCoord][xCoord] !== 0)
          return false;
      }

      return true;
    };

    const placeTile = (cells: { yCoord: number, xCoord: number }[], item: MasonryTile) => {
      for(var i = 0; i < cells.length; i++){
        var { yCoord, xCoord } = cells[i];
        layout[yCoord][xCoord] = item.contents.id;
      }
    };

    // first, sort the breakpoints
    let sortedBreakpoints = breakpoints.sort((a, b) => {
      let aSize = parseInt(Object.keys(a)[0]);
      let bSize = parseInt(Object.keys(b)[0]);
      return aSize >= bSize ? 1 : -1;
    });

    // second, assign numColumns based on screenWidth
    var cols = 0;
    sortedBreakpoints.forEach(breakpoint => {
      let breakpointWidth = parseInt(Object.keys(breakpoint)[0]);
      if(containerWidth > breakpointWidth){
        cols = breakpoint[breakpointWidth];
      }
    });

    var layout = [
      emptyRow(cols),
      emptyRow(cols),
    ];

    // third, place each item on the layout
    items.forEach(item => {
      for(var i = 0; i < layout.length; i++){
        for(var j = 0; j < layout[i].length; j++){
          let cells = generateCells(i, j, item);
          if(checkAvailability(cells)){
            placeTile(cells, item);

            // so the layout grows before the space is needed
            if(i > layout.length - 4){
              addEmptyRow(cols);
              addEmptyRow(cols);
            }

            return;
          }
        }
      }
    });

    setLayout(layout);
  }, [containerWidth, items]);

  // takes empty rows off the end of the layout for rendering
  const trimmedLayout = () => {
    var newLayout = masonryLayout;
    for(var i = masonryLayout.length - 1; i >= 0; i--){
      var flag = true;
      for(var j = 0; j < masonryLayout[i].length; j++){
        if(masonryLayout[i][j] !== 0)
          flag = false;
      }
      if(flag)
        newLayout.pop();
    }

    return newLayout;
  };


  const theme = useTheme();
  const style = useStyle();
  function useStyle(){
    return makeStyles({
      root: {
        width: '100%',
        height: `${trimmedLayout().length * cellDim}px`,
        position: 'relative',
      }
    })();
  }

  const getTiles = () => {
    var placedIds: number[] = [];
    var tiles = [];

    for(var i = 0; i < masonryLayout.length; i++){
      for(var j = 0; j < masonryLayout[i].length; j++){
        if(placedIds.includes(masonryLayout[i][j]))
          continue;

        var tile = items.find(item => item.contents.id == masonryLayout[i][j]);
        if(tile){
          placedIds.push(masonryLayout[i][j]);
          tiles.push(
            <CouponTile
              onClick={onTileClick}
              key={tile.contents.id}
              width={tile.colSpan * cellDim}
              height={tile.rowSpan * cellDim}
              x={j * cellDim}
              y={i * cellDim}
              padding={tilePadding}
              coupon={tile.contents} />
          );
        }
      }
    }

    return tiles;
  };

  return (
    <Grid item container className={style.root}>
      {
        getTiles()
      }
    </Grid>
  );
};