import { Grid, useTheme, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import PreLoginBanner from '../../components/preLogin/PreLoginBanner';
import PreLoginFormArea from '../../components/preLogin/PreLoginFormArea';
import useInput from '../../features/inputs/useInput';
import { requestResetPasswordEmail } from '../../app/api';
import { validEmailAddress } from '../../app/validators';
import { InputWrapper } from '../../features/inputs/Input';
import { useAppSelector } from '../../app/hooks';




export const ForgotPassword: React.FC = () => {
  const [pwResetRequested = false, setPwResetRequested] = useState<boolean>();
  const [userEmail, setUserEmail] = useState<string>('');
  const user = useAppSelector(state => state.user);
  const theme = useTheme();
  const style = useStyles();

  function useStyles() {
    return makeStyles({
      root: {
        [theme.breakpoints.up('md')]: {
          height: '100vh'
        }
      }
    })();
  };

  function requestPwReset(){
    setPwResetRequested(true);
  }

  return (
    <Grid item container className={style.root}>
      <PreLoginBanner />
      {
        pwResetRequested ?
          <CheckYourEmail email={user.user?.email || userEmail} /> :
          <StartResetFlow requested={requestPwReset} setUserEmail={setUserEmail}/>
      }
    </Grid>
  );
};


interface CheckYourEmailProps{
  email: string
}


/**
 * A component confirming that a password reset email has been sent.
 */
export const CheckYourEmail: React.FC<CheckYourEmailProps> = (props: CheckYourEmailProps) => {
  return (
    <PreLoginFormArea>
      <Typography variant={'h1'} align={'center'}>
        Check Your Email
      </Typography>
      <Typography variant={'body1'} align={'center'}>
        An email has been sent to <span style={{ color: '#FF463A', fontWeight: 'bold' }}>{ props.email }</span>
      </Typography>
      <Typography variant={'body1'} align={'center'}>
        Click the link in the email to reset your password.
      </Typography>
    </PreLoginFormArea>
  );
};



interface StartResetFlowProps{
  requested: () => void,
  setUserEmail: (email: string) => void
}

/**
 * A component allowing the user to request a password reset email.
 */
export const StartResetFlow: React.FC<StartResetFlowProps> = (props: StartResetFlowProps) => {
  const { handleInputChange, validate, isDisabled, inputValues, inputErrors } = useInput();
  const user = useAppSelector(state => state.user);

  function sendPwResetEmail(){
    requestResetPasswordEmail(inputValues['forgotPwEmail']);
    props.requested();
  }

  function inputChange(event: React.ChangeEvent<HTMLInputElement>){
    handleInputChange(event);
    props.setUserEmail(event.target.value);
  }

  let defaultEmail = user.user?.email;
  if(defaultEmail && defaultEmail.length > 0)
    inputValues['forgotPwEmail'] = defaultEmail;

  return (
    <PreLoginFormArea>
      <Typography variant={'h1'} align={'center'}>
        Forgot Password
      </Typography>
      <Typography variant={'body1'} align={'center'}>
        Click below to reset your password.
      </Typography>
      <br/>
      <InputWrapper
        id='forgotPwEmail'
        variant='standard'
        label='Email Address'
        type='text'
        defaultValue={defaultEmail}
        disabled={defaultEmail !== undefined && defaultEmail.length > 0}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => inputChange(event)}
        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => validate(event.target.id,  [validEmailAddress()])}
        errMsg={inputErrors['forgotPwEmail']}
      />
      <Button disabled={user.user?.email ? false : isDisabled(true)} onClick={sendPwResetEmail} variant="contained">Reset Password</Button>
      <Button href="/signin" variant="text">Back to sign in</Button>
    </PreLoginFormArea>
  );
};
