import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { deleteCoupon, fetchCoupons, redeemCoupon } from './coupon.thunk';
import { CouponState } from './coupon.types';

export const initialState: CouponState = {
  coupons: [],
  loading: false,
};

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoupons.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.coupons = action.payload;
        state.loading = false;
      })
      .addCase(fetchCoupons.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(redeemCoupon.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(redeemCoupon.fulfilled, (state, action) => {
        state.coupons.unshift(action.payload);
        state.loading = false;
      })
      .addCase(deleteCoupon.fulfilled, (state, action) => {
        state.coupons.splice(state.coupons.findIndex(c => c.id === action.payload.id), 1);
      });
  },
});

export const selectAllCoupons = (state: RootState) => state.coupons.coupons;
export const selectCouponsLoading = (state: RootState) => state.coupons.loading;

export default couponSlice.reducer;
