import { Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const PreLoginFormArea: React.FC = (props) => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles(){
    return makeStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 25px 25px 25px',
        [theme.breakpoints.up('md')]: {
          padding: '0px 75px'
        }
      },
      form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '315px',
        width: '100%',
        textAlign: 'center'
      }
    })();
  }

  return (
    <Grid item container className={style.root} xs={12} md={6} >
      <Grid item className={style.form}>
        { props.children }
      </Grid>
    </Grid>
  );
};

export default PreLoginFormArea;