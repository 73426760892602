import { Button, Grid, useTheme } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { AppRoutes } from '../../app/routes';

interface SubPageWrapperProps {
  to: AppRoutes
}

export const SubPageWrapper: React.FC<SubPageWrapperProps> = ({to, children}) => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles(){
    return makeStyles({
      root: {
        width: '100%',
        padding: '25px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minHeight: 'calc(100vh - 300px)',
        [theme.breakpoints.up('md')]: {
          padding: '30px 200px 100px 200px'
        }
      },
      backButton: {
        boxSizing: 'content-box',
        position: 'relative',
        left: '-10px',
        margin: '0!important',
        padding: '10px 10px 10px 0!important'
      },
      bigIcon: {
        fontSize: '3em!important'
      }
    })();
  }

  return (
    <Grid className={style.root} item container>
      <Button href={to} aria-label="back" className={style.backButton}>
        <ChevronLeft className={style.bigIcon} /> Back
      </Button>
      { children }
    </Grid>
  );
};