import {
  Typography,
  Button,
  Link, Snackbar
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/core/Alert';
import { useAppDispatch } from '../app/hooks';
import PreLoginFormArea from '../components/preLogin/PreLoginFormArea';
import useInput from '../features/inputs/useInput';
import { fetchCurrentUser, signin } from '../features/user/data/user.thunks';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../app/routes';
import { validEmailAddress, validRequiredField } from '../app/validators';
import { InputWrapper } from '../features/inputs/Input';
import { forwardRef, useState } from 'react';
import { isFirebaseError } from '../app/api';
import { FirebaseErrorToClientMessages, UNKNOWN_ERROR } from '../app/errors';
import ReactGA from 'react-ga4';
import User from '../features/user/data/user.model';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const SignInForm: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { handleInputChange, validate, validateAll, isDisabled, inputValues, inputErrors } = useInput();
  const [toastErr, setErr] = useState<string>('');

  function submit() {
    if (validateAll(signinFields)) {
      sign_in();
    }
  }

  function handleClose() {
    setErr('');
  }

  async function sign_in() {
    var response = await dispatch(
      signin({ email: inputValues.email, password: inputValues.password })
    );

    if (response.meta.requestStatus == 'fulfilled') {
      await dispatch(fetchCurrentUser()).then((response) => {
        if (response.meta.requestStatus == 'fulfilled') {
          if (response.payload) {
            const user = response.payload as User;
            ReactGA._gtag('set', 'user_properties', { 'user_Id': user.id });
          }
        }
      });

      history.push(AppRoutes.HOME);
    }

    else {
      if (isFirebaseError(response)) {
        setErr(FirebaseErrorToClientMessages[response.error.code] || UNKNOWN_ERROR);
      }
    }

  }

  const signinFields = [
    {
      id: 'email',
      variant: 'standard',
      label: 'email',
      type: 'text',
      inputLabelProps: { style: { fontVariant: 'h1' } },
      validators: [
        validEmailAddress()
      ]
    },
    {
      id: 'password',
      variant: 'standard',
      label: 'password',
      type: 'password',
      validators: [
        validRequiredField()
      ]
    },
  ];

  return (
    <PreLoginFormArea>
      <Snackbar open={toastErr.length > 0} autoHideDuration={7000} onClose={handleClose}>
        <Alert severity="error" sx={{ width: '200%' }}>
          {toastErr}
        </Alert>
      </Snackbar>

      <Typography variant={'h1'} align={'center'}>
        Sign In
      </Typography>
      {signinFields.map((fieldProps: any, index: number) => (
        <InputWrapper
          key={index}
          id={fieldProps.id}
          variant={fieldProps.variant}
          label={fieldProps.label}
          inputLabelProps={fieldProps.inputLableProp}
          type={fieldProps.type}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => validate(event.target.id, fieldProps.validators)}
          errMsg={inputErrors[fieldProps.id]}
        />
      ))}
      <Button href='/forgot-password' variant='text'>
        Forgot password
      </Button>
      <Button
        disabled={isDisabled(true)}
        onClick={submit}
        variant='contained'
      >
        Sign In
      </Button>
      <Typography variant='body1' style={{ marginTop: '25px' }}>
        Don&apos;t have an account?{' '}
        <Link href='/signup' style={{ fontWeight: 'bold' }}>
          Create Account
        </Link>
      </Typography>
    </PreLoginFormArea>
  );
};
