import axios, { AxiosRequestConfig } from 'axios';
import { Config } from './config';
import { Storage } from './storage';

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE'
}

interface StdError {
  Message: string;
  Data: any;
}

interface FirebaseError{
  error: {
    code: string
  }
}

export function isStdError(error: any = {}): error is StdError {
  return (error as StdError).Message !== undefined;
}

export function isFirebaseError(error: any = {}): error is FirebaseError {
  return (error as FirebaseError).error !== undefined
    && (error as FirebaseError).error.code !== undefined;
}

const httpClient = axios.create();

export const apiRequest = async (
  method: HTTP_METHOD,
  urlPath: string,
  params?: any,
  body?: any,
  headers?: Record<string, string>,
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'
) => {

  const apiUrl = await Config.getApiUrl();
  const token = Storage.getAuthToken();

  try {
    const request: AxiosRequestConfig = {
      url: `${apiUrl}${urlPath}`,
      method: method,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      },
      data: body,
      params,
      responseType
    };

    return await (await httpClient.request(request)).data;

  } catch (error: any) {
    if(isStdError(error))
      return { ...error };
    else
      return new Promise((_, reject) => reject(error));
  }
};

export const requestResetPasswordEmail = async (
  email: string
) => {
  const apiUrl = await Config.getApiUrl();

  try{
    const request: AxiosRequestConfig = {
      url: `${apiUrl}/user/resetpassword`,
      method: HTTP_METHOD.POST,
      params: {
        email
      }
    };

    var response = await (await httpClient.request(request)).data;
    return response;
  } catch (error){
    return new Promise((_, reject) => reject(error));
  }
};

