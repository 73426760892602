import { Drawer, List, Box, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { AppRoutes } from '../app/routes';
import ListItemButton from '@material-ui/core/ListItemButton/ListItemButton';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useAppDispatch } from '../app/hooks';
import { signout } from '../features/user/data/user.thunks';
import { DescriptionOutlined } from '@material-ui/icons';

interface MenuProps {
  isOpen: boolean;
  onClose: Function;
}

const HamburgerMenu: React.FC<MenuProps> = ({
  isOpen,
  onClose,
}) => {
  const [open, setOpen] = useState(true);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleClick = () => {
    setOpen(!open);
  };

  const theme = useTheme();
  const style = useStyles();

  function useStyles(){
    return makeStyles({
      nested: {
        paddingLeft: '40px!important',
      },
    })();
  }

  const sign_out = () => {
    dispatch(
      signout()
    ).then(response => {
      if(response.meta.requestStatus == 'fulfilled'){
        localStorage.clear();
        history.push(AppRoutes.SIGN_IN);
      }
      else{
        alert('An error has occurred. Please try again.');
      }
    });
  };


  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      onClose={() => onClose()}
    >
      <Box
        sx={{ width: 300, paddingTop: '30px' }}
        role="presentation"
      >
        <List>
          {/*
          IN CASE WE WANT TO INCLUDE A FILTERING SUB-MENU

          <ListItemButton component={Link} to={AppRoutes.HOME} onClick={handleClick}>
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Coupons'} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItemButton className={style.nested} component={Link} to={AppRoutes.HOME} onClick={() => onClose()}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="All" />
              </ListItemButton>
              <ListItemButton className={style.nested} component={Link} to={AppRoutes.HOME} onClick={() => onClose()}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Available" />
              </ListItemButton>
              <ListItemButton className={style.nested} component={Link} to={AppRoutes.HOME} onClick={() => onClose()}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Redeemed" />
              </ListItemButton>
            </List>
          </Collapse> */}
          <ListItemButton component={Link} to={AppRoutes.HOME} onClick={() => onClose()}>
            <ListItemIcon>
              <DescriptionOutlined />
            </ListItemIcon>
            <ListItemText primary={'Coupons'} />
          </ListItemButton>
          <ListItemButton component={Link} to={AppRoutes.HELP} onClick={() => onClose()}>
            <ListItemIcon>
              <HelpOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Help Center'} />
          </ListItemButton>
          <ListItemButton component={Link} to={AppRoutes.ACCOUNT} onClick={() => onClose()}>
            <ListItemIcon>
              <PersonOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'My Account'} />
          </ListItemButton>
          <ListItemButton component={Link} to={AppRoutes.ABOUT} onClick={() => onClose()}>
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'About'} />
          </ListItemButton>
          <ListItemButton onClick={() => sign_out()}>
            <ListItemIcon>
              <ExitToAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Sign Out'} />
          </ListItemButton>
        </List>
      </Box>

    </Drawer>
  );
};

export default HamburgerMenu;
