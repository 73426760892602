import { createAsyncThunk } from '@reduxjs/toolkit';
import { HTTP_METHOD, apiRequest } from '../../../app/api';
import Coupon, { LocationData } from './coupon.model';

export const fetchAllCoupons = createAsyncThunk(
  'coupon/fetchAllCoupons',
  async () => {
    const data = await apiRequest(HTTP_METHOD.GET, '/coupon', { pageSize: 100 });
    if (!Array.isArray(data.data)) {
      console.error('expected array response data from GET /coupons');
      return [];
    }
    return data.data.map((d: Coupon) => new Coupon(d));
  }
);

export const fetchCoupons = createAsyncThunk(
  'coupon/fetchCoupons',
  async (filter: string) => {
    const data = await apiRequest(HTTP_METHOD.GET, '/coupon', { pageSize: 100 });
    if (!Array.isArray(data.data)) {
      console.error('expected array response data from GET /coupons');
      return [];
    }

    switch(filter){
    case 'all':
      return data.data.map((d: Coupon) => new Coupon(d));
    case 'redeemed':
      return data.data.map((d: Coupon) => new Coupon(d)).filter((coupon: Coupon) => coupon.redeemed);
    case 'available':
      return data.data.map((d: Coupon) => new Coupon(d)).filter((coupon: Coupon) => !coupon.redeemed);
    default:
      return data.data.map((d: Coupon) => new Coupon(d));
    }
  }
);

export const redeemCoupon = createAsyncThunk(
  'coupon/redeemCoupon',
  async (data: {coupon: Coupon, loc: LocationData}) => {
    const redeemed = await apiRequest(HTTP_METHOD.POST, `/coupon/${data.coupon.id}/redeem`, null, data.loc);
    return redeemed;
  }
);

export const deleteCoupon = createAsyncThunk(
  'coupon/deleteCoupon',
  async (coupon: Coupon) => {
    const data = await apiRequest(HTTP_METHOD.DELETE, `/coupon/${coupon.id}`);
    return new Coupon(data);
  }
);

export const generatePDF = createAsyncThunk(
  'coupon/generatePDF',
  async (coupon: Coupon) => {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      `/coupon/${coupon.id}/pdf`,
      undefined,
      undefined,
      undefined,
      'blob'
    );
    return data;
  }
);

export const generateEmail = createAsyncThunk(
  'coupon/generateEmail',
  async (couponData: { coupon: Coupon, pdfData: string }) => {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      `/coupon/${couponData.coupon.id}/email`,
      undefined,
      JSON.stringify(couponData.pdfData),
      {'Content-Type': 'application/json'},
      'blob'
    );
    return data;
  }
);