import { createAsyncThunk } from '@reduxjs/toolkit';
import { HTTP_METHOD, apiRequest, isStdError } from '../../../app/api';
import { Storage } from '../../../app/storage';
import User from './user.model';
import { SigninUser, SignupUser, Message } from './user.types';
import { lazyFirebase } from '../../../app/firebaseConfig';

export const fetchCurrentUser = createAsyncThunk(
  'user/fetchCurrentUser',
  async () => {
    const data = await apiRequest(HTTP_METHOD.GET, '/user/me');

    if(isStdError(data)){
      return undefined;
    }
    else{
      return new User(data);
    }
  }
);

export const updateCurrentUser = createAsyncThunk(
  'user/updateCurrentUser',
  async (user: any) => {
    const data = await apiRequest(HTTP_METHOD.POST, '/user/me', undefined, user);

    if(isStdError(data)){
      alert(data.Message);
    }
    else{
      return data;
    }
  }
);

export const signin = createAsyncThunk(
  'user/signin',
  async (user: SigninUser) => {
    const { auth } = await lazyFirebase();
    const userCredential = await auth.signInWithEmailAndPassword(user.email, user.password);
    const token = await userCredential.user?.getIdToken();
    if (token) {
      Storage.setAuthToken(token);
    }
    return new User({
      'email': userCredential.user?.email,
      'id': userCredential.user?.uid,
    });
  }
);

export const signout = createAsyncThunk(
  'user/signout',
  async () => {
    const { auth } = await lazyFirebase();
    await auth.signOut();
  }
);

export const signup = createAsyncThunk(
  'user/register',
  async (user: SignupUser, { rejectWithValue }) => {

    try {
      const data = await apiRequest(HTTP_METHOD.POST, '/user/register', null, user);
      if (data['idToken']) {
        Storage.setAuthToken(data['idToken']);
      }
      return new User(data);
    } catch (error) {
      return rejectWithValue( error.response.data ?? error.response);
    }
  }
);

export const validateEmailInvitation = createAsyncThunk(
  'user/register/valid-invite',
  async (email: string) => {
    //TODO: api does not use an request object yet
    const data = await apiRequest(HTTP_METHOD.POST, '/user/register/valid-invite', null, { email: email });
    return data;
  }
);


export const messageUs = createAsyncThunk(
  'user/message-us',
  async (message: Message) => {
    const data = await apiRequest(HTTP_METHOD.POST, '/contact/message',null,message);
    return data;
  }
);
