import { Grid, Typography, useTheme, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useState } from 'react';
import PreLoginBanner from '../../components/preLogin/PreLoginBanner';
import PreLoginFormArea from '../../components/preLogin/PreLoginFormArea';
import useInput from '../../features/inputs/useInput';
import { validPassword, validPwMatch } from '../../app/validators';
import { lazyFirebase } from '../../app/firebaseConfig';
import { useLocation } from 'react-router-dom';
import { InputWrapper } from '../../features/inputs/Input';

/**
 *
 */
export const CreateNewPassword: React.FC = () => {
  const [pwReset = false, setPwReset] = useState<boolean>();
  const theme = useTheme();
  const style = useStyles();

  function useStyles() {
    return makeStyles({
      root: {
        [theme.breakpoints.up('md')]: {
          height: '100vh'
        }
      }
    })();
  };

  function reset(){

    setPwReset(true);
  }

  return (
    <Grid item container className={style.root}>
      <PreLoginBanner />
      {
        pwReset ?
          <ResetSuccessful /> :
          <NewPassword reset={reset} />
      }
    </Grid>
  );
};


export const ResetSuccessful: React.FC = () => {
  return (
    <PreLoginFormArea>
      <Typography variant='h1' align='center'>
        Your password has been reset!
      </Typography>
      <Button href="/signin" variant="contained" style={{ marginTop: '35px' }}>Got it!</Button>
    </PreLoginFormArea>
  );
};


interface NewPasswordProps {
  reset: () => void;
}
export const NewPassword: React.FC<NewPasswordProps> = (props: NewPasswordProps) => {
  const { handleInputChange, validate, validateAll, isDisabled, inputValues, inputErrors } = useInput();
  const location = useLocation();

  function submit(){
    if(validateAll(newPwFields)){
      resetPassword();
    }
  }

  //TODO: drew we should dispatch this since it's a side effect
  async function resetPassword(){
    const instance = await lazyFirebase();
    const oobCode = new URLSearchParams(location.search).get('oobCode');
    instance.auth.confirmPasswordReset(oobCode, inputValues['newPw']);
    props.reset();
  }

  const newPwFields = [
    {
      id: 'newPw',
      variant: 'standard',
      label: 'New Password',
      type: 'password',
      inputLabelProps: { style: { fontVariant: 'h1' } },
      validators: [
        validPassword()
      ]
    },
    {
      id: 'newPwConfirm',
      variant: 'standard',
      label: 'Confirm New Password',
      type: 'password',
      validators: [
        validPwMatch(inputValues['newPw'])
      ]
    },
  ];

  return (
    <PreLoginFormArea>
      <Typography variant='h1' align='center' style={{ whiteSpace: 'nowrap' }}>
        Create New Password
      </Typography>
      <Typography variant='body1' align='center'>
        Your new password must be different from any previously used password
      </Typography>
      {newPwFields.map((fieldProps: any, index: number) => (
        <InputWrapper
          key={index}
          id={fieldProps.id}
          variant={fieldProps.variant}
          label={fieldProps.label}
          inputLabelProps={fieldProps.inputLableProp}
          type={fieldProps.type}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => validate(event.target.id, fieldProps.validators)}
          errMsg={inputErrors[fieldProps.id]}
        />
      ))}
      <Button disabled={isDisabled(true)} onClick={submit} variant="contained" style={{ marginTop: '35px' }}>Reset Password</Button>
    </PreLoginFormArea>
  );
};
