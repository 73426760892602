import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { optionalPhone, validEmailAddress, validRequiredField } from '../../app/validators';
import useInput from '../../features/inputs/useInput';
import { InputWrapper } from '../../features/inputs/Input';
import { SubPageWrapper } from '../../components/postLogin/SubPageWrapper';
import { AppRoutes } from '../../app/routes';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { messageUs } from '../../features/user/data/user.thunks';
import { phoneFormatter } from '../../app/formatters';


export const MessageUs: React.FC = () => {
  const theme = useTheme();
  const style = useStyles();
  const user = useAppSelector(state => state.user);
  const {
    handleInputChange,
    handleSelectChange,
    validate,
    validateAll,
    isDisabled,
    inputValues,
    inputErrors
  } = useInput();
  const history = useHistory();
  const dispatch = useAppDispatch();

  function useStyles() {
    return makeStyles({
      buttonsWrapper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '315px'
        }
      }
    })();
  }

  function submit() {
    if (validateAll(messageUsFields)) {
      sendMessage();
    }
  }

  function sendMessage() {
    const { firstName, lastName, phone, email, reason, message } = inputValues;

    dispatch(messageUs({
      firstName,
      lastName,
      phone,
      email,
      reason: reason ?? 'No reason',
      details: message
    })).then(response => {
      console.log(response);
      if (response.meta.requestStatus == 'fulfilled') {
        history.push(AppRoutes.MSG_SENT);
      }
      else {
        alert('An error has occurred. Please try again.');
      }
    });

  }

  const messageUsFields = [
    {
      id: 'firstName',
      variant: 'standard',
      label: 'First Name',
      type: 'text',
      validators: [
        validRequiredField()
      ]
    },
    {
      id: 'lastName',
      variant: 'standard',
      label: 'Last Name',
      type: 'text',
      validators: [
        validRequiredField()
      ]
    },
    {
      id: 'phone',
      variant: 'standard',
      label: 'Phone (Optional)',
      type: 'text',
      validators: [
        optionalPhone()
      ],
      formatter: phoneFormatter
    },
    {
      id: 'email',
      variant: 'standard',
      label: 'Email',
      type: 'text',
      defaultValue: user.user?.email,
      validators: [
        validEmailAddress()
      ]
    },
    {
      id: 'reason',
      variant: 'standard',
      label: 'Reason for Contact',
      type: 'select',
      options: [
        {
          msg: 'My coupon was not accepted by a sponsor'
        },
        {
          msg: 'I redeemed a coupon by mistake'
        },
        {
          msg: 'My login is not working'
        },
        {
          msg: 'My school email has changed & I need it to be updated in the system'
        },
        {
          msg: 'I transferred schools & I need it to be updated in the system'
        },
        {
          msg: 'I cannot reset my password'
        },
        {
          msg: 'Other'
        },
      ],
      validators: [
        validRequiredField()
      ]
    },
    {
      id: 'message',
      variant: 'outlined',
      label: 'Message',
      type: 'multiline',
      inputLabelProps: {
        style: {
          marginTop: '10px'
        }
      },
      validators: [
        validRequiredField()
      ]
    },
  ];

  return (
    <SubPageWrapper to={AppRoutes.HELP}>
      <Grid item className={style.buttonsWrapper}>
        <Typography variant="h1">
          Message Us
        </Typography>
        {messageUsFields.map((fieldProps: any, index: number) => (
          <InputWrapper
            key={index}
            id={fieldProps.id}
            variant={fieldProps.variant}
            label={fieldProps.label}
            inputLabelProps={fieldProps.inputLableProp}
            type={fieldProps.type}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, fieldProps.formatter)}
            onBlur={(event: React.ChangeEvent<HTMLInputElement>) => validate(event.target.id, fieldProps.validators)}
            onSelect={(event: React.ChangeEvent<HTMLSelectElement>) => handleSelectChange(event)}
            onSelectBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => validate(event.target.id, fieldProps.validators)}
            errMsg={inputErrors[fieldProps.id]}
            defaultValue={fieldProps.defaultValue}
            options={fieldProps.options}
          />
        ))}
        <Button
          disabled={isDisabled(true)}
          onClick={submit}
          variant='contained'
        >
          Submit
        </Button>
      </Grid>
    </SubPageWrapper>
  );
};