import React from 'react';
import {
  Redirect,
  Route
} from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { AppRoutes } from '../../app/routes';
import { selectAttemptedToFetchUser, selectCurrentUser, selectUserLoading } from './data/user.slice';

import { UserRoles } from './data/user.types';

interface AuthRouteProps {
  path: string;
  component: React.FC;
  authorizedRoles: UserRoles[];
  redirectTo?: string;
  exact?: boolean;
}

const AuthRoute: React.FC<AuthRouteProps> = ({
  redirectTo,
  component,
  path,
  exact,
  authorizedRoles,
  ...rest
}) => {

  const attemptedFetch = useAppSelector(selectAttemptedToFetchUser);
  const user = useAppSelector(selectCurrentUser);
  const userLoading = useAppSelector(selectUserLoading);

  const getIsAuthorized = (routeRoles: UserRoles[], userRoles: UserRoles[]) => {
    return !!routeRoles.filter(role => userRoles.includes(role)).length;
  };

  return (
    <Route
      {...rest}
      path={path}
      render={() => {
        if (attemptedFetch && !user && !authorizedRoles.includes(UserRoles.Guest)) {
          return <Redirect to={redirectTo ? redirectTo : AppRoutes.SIGN_IN} />;
        }

        if (user && authorizedRoles.length == 1 && authorizedRoles[0] == UserRoles.Guest){
          return <Redirect to={redirectTo ? redirectTo : AppRoutes.HOME} />;
        }

        if (user && !getIsAuthorized(authorizedRoles, user.roles)) {
          return <Redirect to={redirectTo ? redirectTo : AppRoutes.SIGN_IN} />;
        }

        const Component = component;
        return <Component />;
      }}
    />
  );
};

export default AuthRoute;
