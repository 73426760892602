import { FormControl, FormHelperText, InputLabel, NativeSelect, TextField } from '@material-ui/core';

interface SelectOptionProps{
  msg: string
}
interface InputProps {
  id: string;
  label: string;
  variant: 'standard' | 'filled' | 'outlined' | undefined;
  inputLabelProps?: Object;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onSelect?: React.ChangeEventHandler<HTMLSelectElement>;
  onSelectBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  type: string;
  autoComplete?: string;
  errMsg?: string;
  defaultValue?: string;
  required?: boolean;
  options?: SelectOptionProps[];
  disabled?: boolean;
}

export const InputWrapper: React.FC<InputProps> = ({
  id,
  label,
  variant,
  inputLabelProps,
  onChange,
  onBlur,
  onSelect,
  onSelectBlur,
  type,
  autoComplete,
  errMsg,
  required,
  defaultValue,
  options,
  disabled
}) => {
  switch (type){
  case 'text':
  case 'password':
    return (
      <TextField
        id={id}
        variant={variant}
        label={label}
        InputLabelProps={inputLabelProps}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
        helperText={errMsg}
        defaultValue={defaultValue}
        error={errMsg !== undefined && errMsg.length > 0 ? true : false}
        required={required}
        disabled={disabled}
      />
    );
  case 'select':
    return (
      <FormControl error={errMsg !== undefined && errMsg.length > 0 ? true : false}>
        <InputLabel variant="standard" htmlFor={id}>
          {label}
        </InputLabel>
        <NativeSelect
          variant={variant}
          inputProps={{ id }}
          onChange={onSelect}
          onBlur={onBlur}
        >
          <option value="">{label}</option>
          {
            options?.map((option, index) => (
              <option key={index}>
                {option.msg}
              </option>
            ))
          }
        </NativeSelect>
        <FormHelperText>{errMsg}</FormHelperText>
      </FormControl>
    );
  case 'multiline':
    return (
      <TextField
        multiline
        id={id}
        variant={variant}
        label={label}
        InputLabelProps={inputLabelProps}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
        helperText={errMsg}
        defaultValue={defaultValue}
        error={errMsg !== undefined && errMsg.length > 0 ? true : false}
        required={required}
      />
    );
  default:
    return null;
  }
};
