
import { makeStyles } from '@material-ui/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTheme, Grid, Typography, Link, List, ListItem } from '@material-ui/core';
import footerLogo from '../assets/sie-logo-white.svg';


interface TopNavbarProps extends RouteComponentProps {}

const TopNavbar: React.FC<TopNavbarProps> = ({ location }) => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles() {
    return makeStyles({
      root: {
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        [theme.breakpoints.up('md')]: {
          height: '250px'
        }
      },
      footerLogo: {
        width: '100%',
        maxWidth: '300px'
      },
      footerContact: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
          justifyContent: 'flex-end',
          textAlign: 'left'
        }
      },
      footerContactInner: {
        width: 'fit-content',
      },
      footerPrograms: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
          justifyContent: 'flex-end',
          textAlign: 'left'
        }
      },
      footerProgramsInner: {
        width: 'fit-content'
      },
    })();
  }

  return (
    <Grid item container xs={12} className={style.root}>
      <Grid item container xs={12} sm={10} md={9} style={{ margin: '30px 0 0 30px' }}>
        <Grid item container xs={12} md={4} style={{marginBottom: '25px'}}>
          <img id="footer-logo" src={footerLogo} className={style.footerLogo} />
        </Grid>
        <Grid item container xs={12} md={4} className={style.footerContact} style={{marginBottom: '25px'}}>
          <Grid item className={style.footerContactInner}>
            <Typography variant="body1" color={theme.palette.secondary.contrastText} style={{fontWeight: 'bold'}}>Contact</Typography>
            <Typography variant="body1" color={theme.palette.secondary.contrastText}>
              111 East Broadway, Suite 900<br/>
              Salt Lake City, UT 84111
            </Typography>
            <Typography variant="body1" color={theme.palette.secondary.contrastText}>
              <Link href="tel:8012573577" color={theme.palette.secondary.contrastText}>(801) 257-3577</Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={4} className={style.footerPrograms} style={{marginBottom: '25px'}}>
          <Grid item className={style.footerProgramsInner}>
            <Typography variant="body1" color={theme.palette.secondary.contrastText} style={{fontWeight: 'bold'}}>Programs</Typography>
            <List id="footer-links">
              <ListItem><Link variant="body2" href="https://sieutah.org/programs/#kts" target="_blank">Keys to Success</Link></ListItem>
              <ListItem><Link variant="body2" href="https://sieutah.org/programs/#rts" target="_blank">Road to Success</Link></ListItem>
              <ListItem><Link variant="body2" href="https://sieutah.org/programs/#cts" target="_blank">Code to Success</Link></ListItem>
              <ListItem><Link variant="body2" href="https://www.womenwhosucceed.org/" target="_blank">Women Who Succeed</Link></ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(TopNavbar);
