import axios from 'axios';

interface ConfigFile {
  api_url: string;
  auth_url: string;
  resource_url: string;
  analytics:string;
  firebaseConfig: {
    apiKey: string,
    authDomain: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string
  }
}

export class Config {
  private static configFilePath = '/config.json';
  private static apiUrl?: string;
  private static authUrl?: string;
  private static resourceUrl?: string;
  private static firebaseConfig?: any;
  private static analytics:string;

  public static async getFirebaseConfig() {
    if (!Config.firebaseConfig) {
      await Config.fetchConfigAndCache();
    }
    return Config.firebaseConfig;
  }

  public static async getApiUrl() {
    if (!Config.apiUrl) {
      await Config.fetchConfigAndCache();
    }
    return Config.apiUrl;
  }

  public static async getAnalytics() {
    if (!Config.analytics) {
      await Config.fetchConfigAndCache();
    }
    return Config.analytics;
  }

  public static async getAuthUrl() {
    if (!Config.authUrl) {
      await Config.fetchConfigAndCache();
    }
    return Config.authUrl!;
  }

  public static async getResourceUrl() {
    if (!Config.resourceUrl) {
      await Config.fetchConfigAndCache();
    }
    return Config.resourceUrl!;
  }

  private static async fetchConfigAndCache(): Promise<void> {
    try {

      const config = await (await axios.get(Config.configFilePath)).data as ConfigFile;
      Config.apiUrl = config.api_url;
      Config.authUrl = config.auth_url;
      Config.resourceUrl = config.resource_url;
      Config.firebaseConfig = config.firebaseConfig;
      Config.analytics = config.analytics;

    } catch (error) {
      console.error('no config file found');
    }
  }

}