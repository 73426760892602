import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { AppRoutes } from './routes';
import { ThemeProvider } from '@material-ui/core';
import variants from './theme/variants';
import { useAppDispatch } from './hooks';
import { fetchCurrentUser } from '../features/user/data/user.thunks';

// components
import TopNavbar from '../components/nav/TopNavbar';
import Footer from '../components/Footer';
import PreLoginHeader from '../components/preLogin/PreLoginHeader';

// pages
import Landing from '../pages/Landing';
import { About } from '../pages/About';
import { Sponsors } from '../pages/Sponsors';
import { HelpCenter } from '../pages/helpCenter/HelpCenter';
import { CallUs } from '../pages/helpCenter/CallUs';
import { FAQs } from '../pages/helpCenter/FAQs';
import { MessageUs } from '../pages/helpCenter/MessageUs';
import { MessageSent } from '../pages/helpCenter/MessageSent';
import AuthRoute from '../features/user/AuthRoute';
import { UserRoles } from '../features/user/data/user.types';
import User from '../features/user/data/user.model';
import { ForgotPassword } from '../pages/forgotPassword/ForgotPassword';
import { CreateNewPassword } from '../pages/forgotPassword/CreateNewPassword';
import { Home } from '../pages/Home';
import AccountInformation from '../pages/AccountInformation';
import { Signup } from '../pages/Signup';
import ReactGA from 'react-ga4';
import { Config } from './config';

function App() {
  const dispatch = useAppDispatch();

  // check localstorage for token, fetch user, etc.
  // ... anything that has to happen on startup regardless of which page a user enters from
  const onAppInit = () => {
    Config.getAnalytics().then((value) => {
      ReactGA.initialize(value);

      dispatch(fetchCurrentUser()).then((response) => {
        if (response.meta.requestStatus == 'fulfilled') {
          if (response.payload) {
            const user = response.payload as User;
            ReactGA._gtag('set', 'user_properties', { 'user_Id': user.id });
          }
        }
      });
    });
  };

  useEffect(onAppInit, []);

  return (
    <ThemeProvider theme={variants}>
      <Router basename={process.env.PUBLIC_URL}>

        <PreLoginHeader />
        <TopNavbar />
        {/* Other app-level components could go here too like a modal provider, Footer, etc. */}

        <Switch>
          <Route
            exact
            path={AppRoutes.ROOT}
          >
            <Landing />
          </Route>

          <Route
            exact
            path={AppRoutes.SIGN_IN}
          >
            <Landing />
          </Route>

          <Route
            exact
            path={AppRoutes.SIGN_UP}
          >
            <Signup />
          </Route>

          <Route
            exact
            path={AppRoutes.FORGOT_PASSWORD}
          >
            <ForgotPassword />
          </Route>

          <Route
            exact
            path={AppRoutes.CREATE_PASSWORD}
          >
            <CreateNewPassword />
          </Route>

          <AuthRoute
            exact
            path={AppRoutes.HOME}
            component={Home}
            authorizedRoles={[UserRoles.CouponUser, UserRoles.Admin]}
          />

          <AuthRoute
            exact
            path={AppRoutes.ACCOUNT}
            component={AccountInformation}
            authorizedRoles={[UserRoles.CouponUser, UserRoles.Admin]}
          />

          <AuthRoute
            exact
            path={AppRoutes.ABOUT}
            component={About}
            authorizedRoles={[UserRoles.CouponUser, UserRoles.Admin]}
          />

          <AuthRoute
            exact
            path={AppRoutes.SPONSORS}
            component={Sponsors}
            authorizedRoles={[UserRoles.CouponUser, UserRoles.Admin]}
          />

          <AuthRoute
            exact
            path={AppRoutes.HELP}
            component={HelpCenter}
            authorizedRoles={[UserRoles.CouponUser, UserRoles.Admin]}
          />

          <AuthRoute
            exact
            path={AppRoutes.CALL}
            component={CallUs}
            authorizedRoles={[UserRoles.CouponUser, UserRoles.Admin]}
          />

          <AuthRoute
            exact
            path={AppRoutes.FAQ}
            component={FAQs}
            authorizedRoles={[UserRoles.CouponUser, UserRoles.Admin]}
          />

          <AuthRoute
            exact
            path={AppRoutes.MESSAGE}
            component={MessageUs}
            authorizedRoles={[UserRoles.CouponUser, UserRoles.Admin]}
          />

          <AuthRoute
            exact
            path={AppRoutes.MSG_SENT}
            component={MessageSent}
            authorizedRoles={[UserRoles.CouponUser, UserRoles.Admin]}
          />

          {/* The above routes are placeholders but all app routes should be listed here */}

          {/* finally, catch any non-routes and redirect to Home. Another possiblity is to redirect to a dedicated 404 page if we want to */}
          <Route>
            <Redirect to={AppRoutes.HOME}></Redirect>
          </Route>

        </Switch>

        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
