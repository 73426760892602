
export interface Validator{
  f: (val: string) => boolean,
  errMsg: string
}

export const emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2,}$/i;
export const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const passwordRegex = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~])[a-zA-Z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{8,20}$/;

const isValidEmailAddress = (email: string) => {
  return emailRegex.test(email);
};

export const validEmailAddress = () => {
  return {
    f: isValidEmailAddress,
    errMsg: 'Please enter a valid school email address.'
  };
};

const isValidPassword = (password: string) => {
  var result = passwordRegex.test(password);
  return result;
};

export const validPassword = () => {
  return {
    f: isValidPassword,
    errMsg: 'Invalid password. Must be 8-20 characters and contain at least 1 uppercase letter, lowercase letter, number, and special character.'
  };
};

const isValidPhoneNumber = (phone: string) => {
  var result = phoneRegex.test(phone);
  return result;
};

export const validPhone = () => {
  return {
    f: isValidPhoneNumber,
    errMsg: 'Please enter a valid phone number.'
  };
};

export const optionalPhone = () => {
  return {
    f: (phone: string) => !phone || phone.length == 0 || isValidPhoneNumber(phone),
    errMsg: 'Please enter a valid phone number.'
  };
};


const isAMatch = (val: string, valToMatch: string) => {
  return val == valToMatch;
};

export const validPwMatch = (valToMatch: string) => {
  return {
    f: isAMatch.bind(undefined, valToMatch),
    errMsg: 'Passwords must match.'
  };
};

const hasValue = (val: string) => {
  return val !== undefined && val.trim().length > 0;
};

export const validRequiredField = () => {
  return {
    f: hasValue,
    errMsg: 'Please fill out this field'
  };
};