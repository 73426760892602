import { Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Config } from '../../app/config';
import Coupon, { CouponSize } from './data/coupon.model';

interface CouponTileProps extends RouteComponentProps {
  coupon: Coupon;
  onClick: (coupon: Coupon) => void;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  padding?: number;
}

const months = [
  'Jan', 'Feb', 'Mar', 'Apr',
  'May', 'Jun', 'Jul', 'Aug',
  'Sep', 'Oct', 'Nov', 'Dec'
];

const CouponTile: React.FC<CouponTileProps> = ({
  coupon,
  onClick,
  width,
  height,
  x, y,
  padding
}) => {
  const theme = useTheme();
  const style = useStyles();
  const [resourceUrl, setResourceUrl] = useState<string>('');

  useEffect(() => {

    async function getResourceUrl() {
      let url = await Config.getResourceUrl();
      setResourceUrl(url);
    }

    getResourceUrl();

  }, []);

  function useStyles(){
    return makeStyles({
      root: {
        height: height ? `${height}px!important` : 'auto',
        width: width ? `${width}px!important` : '100%',
        position: x || y ? 'absolute' : 'relative',
        top: `${y || '0'}px`,
        left: `${x || '0'}px`,
        //border: '1px solid blue',
        padding: padding ? `0 ${padding}px ${padding}px 0` : '10px',
      },
      tileRoot: {
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.15)',
        padding: '20px 15px 10px 15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      logoImg: {
        flexGrow: 0,
        maxWidth: '80%',
        maxHeight: `${(height || 0) / 3 - 10}px`,
        marginBottom: '20px',
        position: 'relative',
        zIndex: 0
      },
      couponName: {
        textAlign: 'center',
        flexGrow: 0,
        textOverflow: 'ellipsis',
        fontSize: `${
          coupon.couponSize == CouponSize.TITLE ? '24' :
            coupon.couponSize == CouponSize.LARGE ? '22' :
              coupon.couponSize == CouponSize.MED ? '22' :
                coupon.couponSize == CouponSize.SMALL ? '16' : '12'
        }px!important`,
        lineHeight: '1.3em!important',
        maxHeight: `${(height || 0) / 3 - 10}px`,
        [theme.breakpoints.up('md')]: {
          fontSize: `${
            coupon.couponSize == CouponSize.TITLE ? '32' :
              coupon.couponSize == CouponSize.LARGE ? '22' :
                coupon.couponSize == CouponSize.MED ? '22' :
                  coupon.couponSize == CouponSize.SMALL ? '16' : '12'}px`
        }
      },
      couponCodeText: {
        textAlign: 'center',
        maxHeight: `${(height || 0) / 3 - 10}px`
      },
      couponDesc: {
        textAlign: 'center',
        flexGrow: 0,
        marginTop: '5px!important'
      },
      redeemedBanner:{
        display: `${coupon.redeemed ? 'block' : 'none'}!important`,
        position: 'absolute',
        top: '20px',
        left: '0',
        padding: '10px 20px',
        boxShadow: '0 0 6px rgba(0, 0, 0, 0.2)',
        width: 'fit-content!important',
        backgroundColor: 'white'
      },
      redeemedBannerLabel: {
        color: 'red!important'
      }
    })();
  }

  let extraText = '';
  if(coupon.barCode)
    extraText = 'Bar code to scan';
  else if(coupon.qrCode)
    extraText = 'QR code to scan';
  else if(coupon.code)
    extraText = 'Promo code';


  return (
    <Grid item container className={style.root} onClick={() => onClick(coupon)}>
      <Grid item container className={style.tileRoot}>
        <img className={style.logoImg} src={`${resourceUrl}${coupon.logo}`} />
        <Typography variant='body1' className={style.couponName}>
          <b>
            {
              coupon.name.length > 0
                ? coupon.name
                : coupon.locations.length > 0
                  ? 'Offer Varies by Location'
                  : ''
            }
          </b>
        </Typography>
        <Typography variant='body1' className={style.couponCodeText}>
          { extraText }
        </Typography>
        <Grid item container className={style.redeemedBanner}>
          <Typography className={style.redeemedBannerLabel} variant="body2">
            Redeemed
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(CouponTile);
