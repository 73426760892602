import { createTheme } from '@material-ui/core/styles/';


const settings = createTheme({
  palette: {
    primary: { main: '#ff463a', contrastText: '#fff' },
    secondary: { main: '#2f1f69', light: '#E5E2F1', contrastText: '#fff' },
    mode: 'light',
    common: {
      black: '#1f1f1f',
      white: '#ffffff'
    }
  },
});

settings.typography.h1 = {
  fontFamily: '"Noe Text", serif',
  fontWeight: 600,
  fontSize: 28,
  margin: '20px 0',
  color: '#2849C9',
  [settings.breakpoints.up('sm')]: {
    fontSize: 32
  },
  [settings.breakpoints.up('md')]: {
    fontSize: 40
  }
};

settings.typography.h3 = {
  fontFamily: '"Inter", sans-serif',
  fontWeight: 600,
  fontSize: '20px'
};

settings.typography.body1 = {
  fontFamily: '"Inter", sans-serif',
  fontSize: '16px',
  lineHeight: '20px',
  marginBottom: '0.5em'
};


export default settings;