
export interface LocationData {
  value: string,
  name: string,
  text: string
}

export enum CouponSize {
  SMALL = 'small',
  MED = 'medium',
  LARGE = 'large',
  TITLE = 'title'
}

enum SchoolType {
  KTS = 'KTS',
  RTS = 'RTS'
}

export default class Coupon {
  id: number;
  name: string;
  description: string;
  vendor: string;
  createdAt: Date;
  updatedAt: Date;
  expiresAt: Date;
  logo: string;
  redemptionsAllowed: number;
  mustPresentIdCard: boolean;
  code: string;
  qrCode: string;
  barCode: string;
  locations: LocationData[];
  couponSize: CouponSize;
  couponOrder: string;
  illustrativeImage: string;
  illustrativeImage2: string;
  schoolType: SchoolType[];
  redeemed: boolean;
  readonly fullname: string;

  constructor(data: any) {
    this.id = data.id || -1;
    this.name = data.name || '';
    this.couponSize = data.couponSize || 'small';
    this.description = data.description || '';
    this.vendor = data.vendor || '';
    this.createdAt = data.createdAt || new Date(Date.now());
    this.updatedAt = data.updatedAt || new Date(Date.now());
    this.expiresAt = data.expiresAt || new Date(Date.now());
    this.logo = data.logo || '';
    this.redemptionsAllowed = data.redemptionsAllowed || 1;
    this.mustPresentIdCard = data.mustPresentIdCard || true;
    this.code = data.code || '';
    this.qrCode = data.qrCode || '';
    this.barCode = data.barCode || '';
    this.locations = data.locations || [];
    this.couponOrder = data.couponOrder || '';
    this.illustrativeImage = data.illustrativeImage || '';
    this.illustrativeImage2 = data.illustrativeImage2 || '';
    this.schoolType = data.schoolType || [SchoolType.KTS, SchoolType.RTS];
    this.redeemed = data.redeemed;
    this.fullname = data.name + ' ' + data.id;
  }
}
