import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PageWrapper } from '../../components/postLogin/PageWrapper';
import { AppRoutes } from '../../app/routes';



export const MessageSent: React.FC = () => {
  const theme = useTheme();
  const style = useStyles();

  function useStyles(){
    return makeStyles({
      buttonsWrapper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '315px'
        }
      },
      buttonText: {
        width: '90%',
        flexGrow: 0,
        textAlign: 'left',
        paddingLeft: '10px'
      }
    })();
  }

  return (
    <PageWrapper>
      <Grid item className={style.buttonsWrapper}>
        <Typography variant='h1'>
          Your message has been sent
        </Typography>
        <Typography variant='body1'>
          The Success in Education team will get back to you as soon as possible.
        </Typography>
        <br/>
        <Button
          variant='contained'
          href={AppRoutes.HOME}
        >
          Back to Coupons
        </Button>
        <Button
          variant='outlined'
          href={AppRoutes.MESSAGE}
        >
          Send Another Message
        </Button>
      </Grid>
    </PageWrapper>
  );
};