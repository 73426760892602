import { Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';



export const KenGarffLocations: React.FC = () => {
  const theme = useTheme();
  const style = useStyle();
  function useStyle(){
    return makeStyles({
      root: {
        display: 'flex',
        flexDirection: 'row',
        margin: '30px 0 0 0!important'
      },
      dealershipBox: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        width: '50%!important',
        [theme.breakpoints.up('sm')]: {
          width: '33%!important'
        },
        [theme.breakpoints.up('lg')]: {
          width: '20%!important'
        }
      },
      name: {
        color: '#FF463A',
        margin: '0!important'
      },
      street: {

        margin: '0!important'
      },
      addr2: {

        margin: '0!important'
      },
      phone: {

        margin: '0!important'
      }
    })();
  }

  const locations = [
    {
      name: 'Alfa Romeo of Salt Lake City',
      streetAddr: '198 E 500 S',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84111',
      phone: 'Sales: (877) 325-4707 Parts: (801) 903-6140'
    },
    {
      name: 'Audi Lehi',
      streetAddr: '3455 North Digital Drive',
      city: 'Lehi',
      state: 'UT',
      zip: '84043-3455',
      phone: 'Service: (888) 430-6231'
    },
    {
      name: 'Buick GMC Riverdale',
      streetAddr: '900 W. Riverdale Rd.',
      city: 'Ogden',
      state: 'UT',
      zip: '84405',
      phone: 'Service: (801) 627-6730'
    },
    {
      name: 'Chevrolet American Fork',
      streetAddr: '548 East 1000 South',
      city: 'American Fork',
      state: 'UT',
      zip: '84003',
      phone: 'Service: (801) 756-9641'
    },
    {
      name: 'Chrysler Jeep Dodge RAM FIAT West Valley',
      streetAddr: '4175 W 3500 South',
      city: 'West Valley City',
      state: 'UT',
      zip: '84120',
      phone: 'Service: (866) 610-3785'
    },
    {
      name: 'Ferrari of Salt Lake City',
      streetAddr: '543 State St',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84111',
      phone: '(123) 456-1890'
    },
    {
      name: 'Ford American Fork',
      streetAddr: '597 East 1000 South',
      city: 'American Fork',
      state: 'UT',
      zip: '84003',
      phone: 'Service: (801) 763-6830'
    },
    {
      name: 'Ford St. George',
      streetAddr: '145 W. Hilton Drive',
      city: 'Saint George',
      state: 'UT',
      zip: '84770',
      phone: 'Service: (888) 322-4876'
    },
    {
      name: 'Ford West Valley',
      streetAddr: '4091 West 3500 South',
      city: 'West Valley',
      state: 'UT',
      zip: '84120',
      phone: 'Service: (801) 559-0530'
    },
    {
      name: 'Genesis of Sandy',
      streetAddr: '10770 S Auto Mall Dr',
      city: 'Sandy',
      state: 'UT',
      zip: '84072',
      phone: 'Service: (877) 770-5883'
    },
    {
      name: 'Honda Downtown',
      streetAddr: '900 South State Street',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84111',
      phone: 'Service: (801) 526-1730'
    },
    {
      name: 'Honda of Orem',
      streetAddr: '195 E. University Parkway',
      city: 'Orem',
      state: 'UT',
      zip: '84508',
      phone: 'Service: (801) 701-2201'
    },
    {
      name: 'Honda Riverdale',
      streetAddr: '950 West Riverdale Road',
      city: 'Ogden',
      state: 'UT',
      zip: '84405',
      phone: 'Service: (801) 781-4730'
    },
    {
      name: 'Hyundai Downtown',
      streetAddr: '717 South West Temple',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84101',
      phone: 'Service: (801) 257-3230'
    },
    {
      name: 'Hyundai Southtowne',
      streetAddr: '10770 South Auto Mall Drive',
      city: 'Sandy',
      state: 'UT',
      zip: '84070',
      phone: 'Service: (801) 912-9930'
    },
    {
      name: 'Jaguar Downtown Salt Lake',
      streetAddr: '150 E 500 S',
      city: 'city',
      state: 'state',
      zip: 'zip',
      phone: '(123) 456-1890'
    },
    {
      name: 'Jaguar Downtown Salt Lake',
      streetAddr: 'street address',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84111',
      phone:
       `Sales: (801) 257-3600
        Service: (801) 257-3630
        Parts: (801) 257-3640`
    },
    {
      name: 'Jaguar Lehi',
      streetAddr: '2302 Ashton Blvd',
      city: 'Lehi',
      state: 'UT',
      zip: '84043',
      phone: 'Service: (801) 903-4530'
    },
    {
      name: 'Land Rover Downtown Salt Lake',
      streetAddr: '150 East 500 South',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84111',
      phone: 'Service: (801) 257-3630'
    },
    {
      name: 'Land Rover Lehi',
      streetAddr: '2302 Ashton Blvd',
      city: 'Lehi',
      state: 'UT',
      zip: '84043',
      phone: 'Service: (801) 903-4530'
    },
    {
      name: 'Land Rover Lehi',
      streetAddr: 'street address',
      city: 'city',
      state: 'state',
      zip: 'zip',
      phone: '(123) 456-1890'
    },
    {
      name: 'Lincoln St. George',
      streetAddr: '145 W. Hilton Drive',
      city: 'Saint George',
      state: 'UT',
      zip: '84770',
      phone: 'Service: (877) 852-6860'
    },
    {
      name: 'Maserati of Salt Lake City',
      streetAddr: '198 E. 500 S.',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84111',
      phone: 'Service: (801) 903-6130'
    },
    {
      name: 'Mercedes-Benz of Salt Lake City',
      streetAddr: '575 South State Street',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84111',
      phone: 'Service: (855) 780-1052'
    },
    {
      name: 'Nissan of Orem',
      streetAddr: '285 W. University Parkway',
      city: 'Orem',
      state: 'UT',
      zip: '84058',
      phone: 'Service: (801) 494-8630'
    },
    {
      name: 'Nissan Riverdale',
      streetAddr: '615 West Riverdale Road',
      city: 'Riverdale',
      state: 'UT',
      zip: '84405',
      phone: 'Service: (801) 781-4530'
    },
    {
      name: 'Nissan Salt Lake',
      streetAddr: '777 S. West Temple',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84101',
      phone: 'Service: (801) 933-1891'
    },
    {
      name: 'Porsche Lehi',
      streetAddr: '3425 N. Digital Drive',
      city: 'Lehi',
      state: 'UT',
      zip: '84043',
      phone: 'Service: (801) 852-5400'
    },
    {
      name: 'Volkswagen Orem',
      streetAddr: '115 East University Parkway',
      city: 'Orem',
      state: 'UT',
      zip: '84058',
      phone: 'Service: (385) 207-2109'
    },
    {
      name: 'Volvo Cars Salt Lake City',
      streetAddr: '525 South State Street',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84111',
      phone: 'Service: (866) 796-3183'
    },
    {
      name: 'West Valley Used',
      streetAddr: '3500 S. Bangerter HWY',
      city: 'West Valley City',
      state: 'UT',
      zip: '84120',
      phone: 'Service: (801) 938-7993'
    },
  ];

  let dealerships = locations.map((loc, index) => {
    return (
      <Grid key={index} item container className={style.dealershipBox}>
        <Typography variant="body1" className={style.name}>
          <b>{loc.name}</b>
        </Typography>
        <Typography variant="body1" className={style.street}>
          {loc.streetAddr}
        </Typography>
        <Typography variant="body1" className={style.addr2}>
          {`${loc.city}, ${loc.state}, ${loc.zip}`}
        </Typography>
        <Typography variant="body1" className={style.phone}>
          {loc.phone}
        </Typography>
      </Grid>
    );
  });

  return (
    <Grid item container className={style.root}>
      { dealerships }
    </Grid>
  );
};