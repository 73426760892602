import User from './user.model';

export interface UserState {
  user?: User;
  loading: boolean;
  attemptedFetch: boolean;
}

export enum UserRoles {
  Admin = 'admin',
  KTSUser = 'KTSUser',
  RTSUser = 'RTSUser',
  CouponUser = 'CouponUser',
  Guest = 'Guest'
}

export interface SignupUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface SigninUser {
  email: string;
  password: string;
}

export interface Message {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  reason: string;
  details: string;
}