import { UserRoles } from './user.types';

export default class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: UserRoles[];
  school: {
    name: string
  };

  constructor(data: any) {
    this.id = data.id || -1;
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.email = data.email || '';
    this.roles = data.roles || [];
    this.school = data.school || { name: '' };

  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  // Either compose a payload or use all of "this" but delete unauthorized fields like roles
  get updatePayload() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
    };
  }
}
