import {forwardRef, useState} from 'react';
import { Typography, Button, Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import PreLoginBanner from '../components/preLogin/PreLoginBanner';
import PreLoginFormArea from '../components/preLogin/PreLoginFormArea';
import useInput, { TextField } from '../features/inputs/useInput';
import { signup } from '../features/user/data/user.thunks';
import User from '../features/user/data/user.model';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/core/Alert';
import { validEmailAddress, validPassword, validPwMatch, validRequiredField } from '../app/validators';
import { InputWrapper } from '../features/inputs/Input';
import { AppRoutes } from '../app/routes';
import { isStdError } from '../app/api';


const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Signup: React.FC = () => {
  const theme = useTheme();
  const style = useStyles();
  const [accountCreated, setAccountCreated] = useState<boolean>(false);

  function useStyles() {
    return makeStyles({
      root: {
        [theme.breakpoints.up('md')]: {
          height: '100vh'
        }
      }
    })();
  }

  return (
    <Grid container className={style.root}>
      <PreLoginBanner />
      {
        accountCreated
          ?
          <AccountCreated />
          :
          <SignUpForm created={() => { setAccountCreated(true); }} />
      }
    </Grid>
  );
};

export const AccountCreated: React.FC = () => {
  const history = useHistory();

  return (
    <PreLoginFormArea>
      <Typography variant="h1">
        Your account has been created!
      </Typography>
      <Typography variant="body1">
        Sign in to access your account.
      </Typography>
      <br/>
      <Button variant="contained" onClick={() => history.push(AppRoutes.SIGN_IN)}>
        Sign In
      </Button>
    </PreLoginFormArea>
  );
};

interface SignUpFormProps {
  created: () => void
}

export const SignUpForm: React.FC<SignUpFormProps> = ({ created }) => {
  const dispatch = useAppDispatch();
  const { handleInputChange, validate, validateAll, isDisabled, inputValues, inputErrors } = useInput();
  const [toastErr, setErr] = useState<string>('');

  function submit(){
    if(validateAll(signupFields)){
      sign_up();
    }
  }

  async function sign_up(){
    dispatch(
      signup({
        firstName: inputValues['firstName'],
        lastName: inputValues['lastName'],
        email: inputValues['signupEmail'],
        password: inputValues['password']
      })
    ).then(({meta, payload}) => {
      if(meta.requestStatus == 'fulfilled'){
        const returnUser: User = new User(payload);
        if (returnUser.id === -1) {
          setErr('Your email address has not been whitelisted - contact an admin if you think you should have access');
        } else {
          created();
        }
      }
      else{
        if(isStdError(payload)){
          const alreadyExists = Object.values(payload).includes('AlreadyExists');
          if (alreadyExists) {
            setErr('An account with this email address already exists');
          } else {
            setErr(payload.Message);
          }
        }
      }
    });
  }

  function handleClose(){
    setErr('');
  }

  const signupFields: TextField[] = [
    {
      id: 'firstName',
      variant: 'standard',
      label: 'First Name',
      type: 'text',
      autoComplete: 'given-name',
      validators: [
        validRequiredField()
      ]
    },
    {
      id: 'lastName',
      variant: 'standard',
      label: 'Last Name',
      type: 'text',
      autoComplete: 'family-name',
      validators: [
        validRequiredField()
      ]
    },
    {
      id: 'signupEmail',
      variant: 'standard',
      label: 'School Email',
      type: 'text',
      autoComplete: 'off',
      validators: [
        validEmailAddress()
      ]
    },
    {
      id: 'password',
      variant: 'standard',
      label: 'Password',
      type: 'password',
      autoComplete: 'new-password',
      validators: [
        validPassword(),
      ]
    },
    {
      id: 'confirmPassword',
      variant: 'standard',
      label: 'Confirm Password',
      type: 'password',
      validators: [
        validPwMatch(inputValues['password'])
      ]
    },
  ];

  return (
    <PreLoginFormArea>

      <Snackbar open={toastErr.length > 0} autoHideDuration={7000} onClose={handleClose}>
        <Alert severity="error" sx={{ width: '200%' }}>
          {toastErr}
        </Alert>
      </Snackbar>

      <Typography variant={'h1'} align={'center'}>
        Create Account
      </Typography>
      <form
        className='sign-up-form'
      >
        {
          signupFields.map((fieldProps: any, index: number) => {
            return (
              <InputWrapper
                key={index}
                id={fieldProps.id}
                variant={fieldProps.variant}
                label={fieldProps.label}
                inputLabelProps={fieldProps.inputLableProp}
                type={fieldProps.type}
                autoComplete={fieldProps.autoComplete}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                onBlur={(event: React.ChangeEvent<HTMLInputElement>) => validate(event.target.id, fieldProps.validators)}
                required={fieldProps.required}
                errMsg={inputErrors[fieldProps.id]}
              />
            );
          })
        }
        <Button
          disabled={isDisabled(true)}
          onClick={submit}
          style={{ marginTop: '50px' }}
          variant="contained"
        >
          Create Account
        </Button>
      </form>
      <Button href="/signin" variant="text">Back to sign in</Button>
    </PreLoginFormArea>
  );
};
