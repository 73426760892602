import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import couponsReducer from '../features/coupons/data/coupon.slice';
import userReducer from '../features/user/data/user.slice';

export const store = configureStore({
  reducer: {
    coupons: couponsReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['coupons/fetchCoupons/fulfilled', 'user/fetchCurrentUser/fulfilled'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta', 'payload'],
        // Ignore these paths in the state
        ignoredPaths: ['coupons', 'user'],
      },
    }),

});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
