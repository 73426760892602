import { makeStyles } from '@material-ui/styles';
import { Button, Grid, useTheme } from '@material-ui/core';
import PreLoginBanner from '../components/preLogin/PreLoginBanner';
import { SignInForm } from './Signin';
import { useState } from 'react';
import PreLoginFormArea from '../components/preLogin/PreLoginFormArea';
import { AppRoutes } from '../app/routes';

const Landing : React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useTheme();
  const style = useStyles();

  function useStyles() {
    return makeStyles({
      root: {
        minHeight: 'calc(100vh - 300px)',
        [theme.breakpoints.up('md')]: {
          height: '100vh'
        }
      },
      formArea: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '25px',
        [theme.breakpoints.up('sm')]: {
          padding: '100px'
        }
      },
      form: {
        width: '100%',
        maxWidth: '315px'
      },
      onlyMobile: {
        [theme.breakpoints.up('md')]: {
          display: 'none!important'
        }
      },
      onlyDesktop: {
        display: 'none!important',
        [theme.breakpoints.up('md')]: {
          display: 'flex!important'
        }
      }
    })();
  };

  return (
    <Grid container>
      { /* MOBILE VERSION */ }
      <Grid item container className={`${style.onlyMobile} ${style.root}`}>
        <PreLoginBanner displayOverride={!isOpen} />
        {
          isOpen
            ?
            <SignInForm />
            :
            <LandingForm open={() => setIsOpen(true)} />
        }
      </Grid>
      { /* DESKTOP VERSION */ }
      <Grid item container className={`${style.onlyDesktop} ${style.root}`}>
        <PreLoginBanner displayOverride={!isOpen} />
        <SignInForm />
      </Grid>
    </Grid>
  );
};

interface LandingFormProps {
  open: () => void
}

const LandingForm: React.FC<LandingFormProps> = ({open}) => {
  return (
    <PreLoginFormArea>
      <Button
        variant="contained"
        onClick={open}
      >
        Sign In
      </Button>
      <Button
        variant="outlined"
        href={AppRoutes.SIGN_UP}
      >
        Create Account
      </Button>
    </PreLoginFormArea>
  );
};

export default Landing;