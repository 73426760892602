import { Button, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../app/hooks';
import { RootState } from '../app/store';
import { PageWrapper } from '../components/postLogin/PageWrapper';
import { fetchCoupons, generateEmail, generatePDF } from '../features/coupons/data/coupon.thunk';
import { Masonry } from '../components/Masonry';
import Coupon, { CouponSize, LocationData } from '../features/coupons/data/coupon.model';
import { ChevronLeft } from '@material-ui/icons';
import { CouponDetails } from '../features/coupons/CouponDetails';
import { KenGarffLocations } from '../components/KenGarffLocations';

export enum FilterTypes {
  ALL='all',
  AVAILABLE='available',
  REDEEMED='redeemed'
};

export const Home = () => {
  const theme = useTheme();
  const style = useStyles();
  const dispatch = useAppDispatch();
  const coupons = useSelector((state: RootState) => state.coupons.coupons);
  const user = useSelector((state: RootState) => state.user);
  const titleCoupons = coupons.filter(coupon => coupon.couponSize == CouponSize.TITLE);
  const masonryCoupons = coupons.filter(coupon => coupon.couponSize !== CouponSize.TITLE);
  const [currentFilter, setCurrentFilter] = useState<string>(FilterTypes.ALL);
  const masonryContainer = useRef<HTMLDivElement>(null);
  const [activeCoupon, setActiveCoupon] = useState<Coupon|null>(null);
  const [activeLocation, setActiveLocation] = useState<LocationData|undefined>(undefined);
  const [couponDetailsActive, setCouponDetailsActive] = useState<boolean>(false);
  const [couponRedemptionActive, setCouponRedemptionActive] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState<string>('');
  const [pdfPayload, setPdfPayload] = useState<string>('');
  const [attemptedPdfFetch, setAttemptedPdfFetch] = useState<boolean>(false);
  const [downloadedPdf, setDownloadedPdf] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [masonryWidth, setMasonryWidth] = useState<number>(0);
  var [resizeTimeoutId, setResizeTimeoutId] = useState<ReturnType<typeof setTimeout>>();

  function useStyles(){
    return makeStyles({
      contentHeaderBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      titleCouponsBox: {
        margin: '20px 0 0 0!important',
      },
      couponsBox: {
        margin: '20px 0 0 0!important',
      },
      backButton: {
        boxSizing: 'content-box',
        position: 'relative',
        left: '-10px',
        margin: '0!important',
        padding: '10px 10px 10px 0!important'
      },
      bigIcon: {
        fontSize: '3em!important'
      },
      centeredContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      },
      outerBox: {
        [theme.breakpoints.up('sm')]: {
          maxWidth: '450px',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          padding: '50px'
        }
      },
      couponName: {
        width: '100%',
        textAlign: 'center',
        margin: '0 0 30px 0!important'
      },
      couponDescription: {
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: 'Barlow',
        fontSize: '16px',
        margin: '0 0 30px 0!important'
      },
      headerRow: {
        margin: '0 0 30px 0!important',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          justifyContent: 'space-between'
        }
      },
      filterOptionBox: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#DEE3F6',
        [theme.breakpoints.up('sm')]: {
          width: 'fit-content!important'
        }
      },
      filterOption: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '20px 15px',
        height: '100%',
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
          flexGrow: 0,
          width: '120px',
          padding: '10px 15px'
        }
      },
      filterOptionLabel: {
        margin: '0!important',
        textAlign: 'center'
      },
      filterOptionSelected: {
        backgroundColor: '#2849C9',
        borderRadius: '10px',
        color: 'white!important',
        fontWeight: 'bold'
      },
      couponHeader: {
        textAlign: 'center',
        margin: '0 0 30px 0!important',
        [theme.breakpoints.up('sm')]: {
          textAlign: 'left',
          margin: '0!important'
        }
      }
    })();
  }

  function onTileClick(coupon: Coupon){
    setActiveCoupon(coupon);
    setCouponDetailsActive(true);
  }

  function onCloseCouponDetails(){
    setCouponDetailsActive(false);
  }

  async function onHomescreenLoad(){
    await dispatch(fetchCoupons(currentFilter));
    setMasonryWidth(masonryContainer.current?.offsetWidth || 0);
    recalculate();
  }

  function recalculate(){
    const resizeListener = () => {
      setMasonryWidth(masonryContainer.current?.offsetWidth || 0);
    };
    window.addEventListener('resize', () => {
      if(resizeTimeoutId)
        clearTimeout(resizeTimeoutId);
      setResizeTimeoutId(setTimeout(resizeListener, 250));
    });
  }

  function requestPdf(){
    if(!activeCoupon)
      return;

    // generate PDF server side
    dispatch(generatePDF(activeCoupon))
      .then(doc => {
        if(doc.meta.requestStatus == 'fulfilled'){
          //Create a Blob from the PDF Stream
          const file = new Blob(
            [doc.payload],
            {type: 'application/pdf'});

          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);

          var reader = new FileReader();
          reader.readAsDataURL(doc.payload);
          reader.onloadend = function() {
            var base64data = reader.result as string;
            base64data = base64data.slice(base64data.indexOf(',') + 1);
            setPdfPayload(base64data);
            setPdfData(fileURL);
          };
        }
      });
  }

  function sendCouponToEmail(){
    if(activeCoupon){
      dispatch(generateEmail({ coupon: activeCoupon, pdfData: pdfPayload }))
        .then(response => {
          if(response.meta.requestStatus == 'fulfilled')
            setEmailSent(true);
          else
            alert('There was a problem sending the email. Please download your coupon as a PDF or try again later.');
        });
    }
  }

  function filterBy(filter: string){
    switch(filter){
    case 'all':
      setCurrentFilter('all');
      break;
    case 'available':
      setCurrentFilter('available');
      break;
    case 'redeemed':
      setCurrentFilter('redeemed');
      break;
    default:
      setCurrentFilter('all');
      break;
    }
  }

  function exitCouponRedemption(){
    setEmailSent(false);
    setAttemptedPdfFetch(false);
    setPdfData('');
    setDownloadedPdf(false);
    setCouponRedemptionActive(false);
    onHomescreenLoad();
  }

  useEffect(() => {
    onHomescreenLoad();
  }, [currentFilter]);

  useEffect(() => {
    setPdfData('');
  }, [activeCoupon]);

  useEffect(() => {
    onHomescreenLoad();
  }, [window.location.hash]);

  if(couponDetailsActive && activeCoupon){
    return (
      <PageWrapper>
        <Button onClick={onCloseCouponDetails} aria-label="back" className={style.backButton}>
          <ChevronLeft className={style.bigIcon} /> Back
        </Button>
        <Grid item container className={style.centeredContent}>
          <CouponDetails coupon={activeCoupon} onRedeem={(activeLocation?: LocationData) => {
            setActiveLocation(activeLocation);
            setCouponDetailsActive(false);
            setCouponRedemptionActive(true);
            onHomescreenLoad();
          }} />

          {
            activeCoupon.vendor == 'Ken Garff'
              ?
              <KenGarffLocations />
              :
              <></>
          }
        </Grid>
      </PageWrapper>
    );
  }
  else if(couponRedemptionActive && activeCoupon){
    if(pdfData.length <= 0 && !attemptedPdfFetch){
      requestPdf();
      setAttemptedPdfFetch(true);
    }

    return (
      <PageWrapper>
        <Button
          onClick={() => exitCouponRedemption()}
          aria-label="back"
          className={style.backButton}
        >
          <ChevronLeft className={style.bigIcon} /> Back
        </Button>
        <Grid item container className={style.centeredContent}>
          <Grid item container className={style.outerBox}>
            <Typography variant="h1" className={style.couponName}>
              Your coupon has been redeemed!
            </Typography>
            <Typography variant="body1" className={style.couponDescription}>
              Download your coupon right to your device or send it to your email...
            </Typography>
            {
              pdfData.length > 0
                ? downloadedPdf
                  ?
                  <Button disabled variant="outlined">
                    PDF DOWNLOADED!
                  </Button>
                  :
                  <Button
                    variant="contained"
                    download={`SIE_Rewards_${activeCoupon.vendor}.pdf`}
                    href={pdfData}
                    target="_blank"
                    onClick={() => {
                      setDownloadedPdf(true);
                      if(emailSent)
                        exitCouponRedemption();
                    }}>
                    DOWNLOAD PDF
                  </Button>
                :
                <Button disabled variant="outlined">
                  <CircularProgress variant='indeterminate' />
                </Button>
            }
            <br/>
            {
              pdfPayload.length > 0
                ? emailSent
                  ?
                  <Button disabled variant="outlined">
                  EMAIL SENT!
                  </Button>
                  :
                  <Button variant="contained" onClick={() => {
                    sendCouponToEmail();
                    if(downloadedPdf)
                      exitCouponRedemption();
                  }}>
                  SEND TO EMAIL
                  </Button>
                :
                <Button disabled variant="outlined">
                  <CircularProgress variant='indeterminate' />
                </Button>
            }

          </Grid>
        </Grid>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Grid item className={style.contentHeaderBox}>
        <Grid item container className={style.headerRow}>
          <Typography variant='h1' className={style.couponHeader}>
            Coupons
          </Typography>
          <Grid item container className={style.filterOptionBox}>
            <Grid item className={`${style.filterOption} ${currentFilter == 'all' ? style.filterOptionSelected : ''}`} onClick={() => filterBy('all')}>
              <Typography variant="body1" className={style.filterOptionLabel}>
                All
              </Typography>
            </Grid>
            <Grid item className={`${style.filterOption} ${currentFilter == 'available' ? style.filterOptionSelected : ''}`} onClick={() => filterBy('available')}>
              <Typography variant="body1"  className={style.filterOptionLabel}>
                Available
              </Typography>
            </Grid>
            <Grid item className={`${style.filterOption} ${currentFilter == 'redeemed' ? style.filterOptionSelected : ''}`} onClick={() => filterBy('redeemed')}>
              <Typography variant="body1" className={style.filterOptionLabel}>
                Redeemed
              </Typography>
            </Grid>
          </Grid>
        </Grid>



        { /* TODO: add button group here */ }
      </Grid>
      <Typography variant='h3'>
        Title Coupons
      </Typography>
      <Grid item className={style.titleCouponsBox}>
        { /* <TitleCoupons /> */ }
        <Masonry
          onTileClick={onTileClick}
          containerWidth={masonryWidth}
          items={
            titleCoupons.map(coupon => ({ contents: { ...coupon }, rowSpan: 2, colSpan: 2 }))
          }
          breakpoints={[
            { [theme.breakpoints.values.xs]: 2 },
            { [theme.breakpoints.values.sm]: 4 },
            { [theme.breakpoints.values.md]: 6 },
          ]}
        />
      </Grid>
      <Typography variant='h3' style={{margin: '20px 0 0 0'}}>
        Coupons
      </Typography>
      <Grid item container className={style.couponsBox} ref={masonryContainer}>
        { /* <Coupons /> */ }
        <Masonry
          onTileClick={onTileClick}
          containerWidth={masonryWidth}
          items={
            masonryCoupons.map(coupon => {
              let { rowSpan, colSpan } =
                coupon.couponSize == CouponSize.SMALL ? { rowSpan: 1, colSpan: 1 } :
                  coupon.couponSize == CouponSize.MED ? { rowSpan: 2, colSpan: 1 } :
                    coupon.couponSize == CouponSize.LARGE ? { rowSpan: 1, colSpan: 2 } :
                      { rowSpan: 2, colSpan: 2 };
              let item = { contents: { ...coupon }, rowSpan, colSpan };
              return item;
            })
          }
          breakpoints={[
            { [theme.breakpoints.values.xs]: 2 },
            { [theme.breakpoints.values.sm]: 4 },
            { [theme.breakpoints.values.md]: 6 },
          ]}
        />
      </Grid>
    </PageWrapper>
  );
};

function useStyles() {
  return makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })();
}
