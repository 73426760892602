import { AppBar, IconButton, Tab, Tabs, Toolbar, useTheme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import logoRed from '../../assets/sie-logo-red.svg';

import { AppRoutes } from '../../app/routes';
import HamburgerMenu from '../HamburgerMenu';
import { useAppDispatch } from '../../app/hooks';
import { useHistory } from 'react-router-dom';
import { signout } from '../../features/user/data/user.thunks';

interface TopNavbarProps extends RouteComponentProps {}

const TopNavbar: React.FC<TopNavbarProps> = ({ location }) => {
  const history = useHistory();
  const theme = useTheme();
  const style = useStyles();
  const dispatch = useAppDispatch();

  // for Coupons submenu
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  //
  // const handleClick = (event: MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  function useStyles() {
    return makeStyles({
      root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      toolbar: {
        width: '100%',
        justifyContent: 'space-between'
      },
      tabList: {
        marginLeft: 'auto',
        display: 'none!important',
        [theme.breakpoints.up('md')]: {
          display: 'flex!important'
        },
      },
      menuIcon: {
        display: 'inline-flex',
        [theme.breakpoints.up('md')]: {
          display: 'none!important'
        }
      },
      headerLogo: {
        height: '20px',
        [theme.breakpoints.up('md')]: {
          height: '32px'
        }
      },
    })();
  }

  const noNavbarRoutes = [
    AppRoutes.ROOT,
    AppRoutes.SIGN_IN,
    AppRoutes.SIGN_UP,
    AppRoutes.FORGOT_PASSWORD,
    AppRoutes.CREATE_PASSWORD
  ];

  const [
    menuOpen,
    setMenuOpen,
  ] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const sign_out = () => {
    dispatch(
      signout()
    ).then(response => {
      if(response.meta.requestStatus == 'fulfilled'){
        localStorage.clear();
        history.push(AppRoutes.SIGN_IN);
      }
      else{
        alert('An error has occurred. Please try again.');
      }
    });
  };


  // render empty element if location path should not show the nav bar
  if (!location || noNavbarRoutes.includes(location.pathname as AppRoutes)) {
    return <></>;
  }

  return (
    <AppBar
      className={style.root}
      position='static'
      color={'transparent'}
    >
      <Toolbar
        className={style.toolbar}
      >
        <img
          src={logoRed}
          className={style.headerLogo}
        />
        <Tabs
          className={style.tabList}
          value={location.pathname}
          aria-label="Navigation Tabs"
        >
          <Tab label={'Coupons'} value={AppRoutes.HOME} to={AppRoutes.HOME} component={Link} onClick={() => window.location.pathname = '/home'} />
          <Tab label={'Sponsors'} value={AppRoutes.SPONSORS} to={AppRoutes.SPONSORS} component={Link} />
          <Tab label={'Help Center'} value={AppRoutes.HELP} to={AppRoutes.HELP} component={Link} />
          <Tab label={'About'} value={AppRoutes.ABOUT} to={AppRoutes.ABOUT} component={Link} />
          <Tab label={'My Account'} value={AppRoutes.ACCOUNT} to={AppRoutes.ACCOUNT} component={Link} />
          <Tab label={'Logout'} value={AppRoutes.SIGN_IN} onClick={sign_out} style={{ paddingRight: '0' }} />
        </Tabs>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={style.menuIcon}
          onClick={toggleMenu}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <HamburgerMenu
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
      />

      {/*
      USE THIS IF YOU WANT TO ADD A COUPON SUB-MENU FOR FILTERING

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>All</MenuItem>
        <MenuItem onClick={handleClose}>Available</MenuItem>
        <MenuItem onClick={handleClose}>Redeemed</MenuItem>
      </Menu> */}
    </AppBar>
  );
};

function useStyles() {
  return makeStyles({
    root: {

    },
    tabList: {
      marginLeft: 'auto'
    }
  })();
}

export default withRouter(TopNavbar);
