
import firebase from 'firebase/app';
import 'firebase/auth';
import { Config } from './config';

// const { REACT_APP_APIKEY, REACT_APP_AUTHDOMAIN,
//   REACT_APP_PROJECTID,
//   REACT_APP_STORAGEBUCKET,
//   REACT_APP_MESSAGINGSENDERID,
//   REACT_APP_APPID } = process.env;

let instance:any = null;

export const lazyFirebase = async () => {
  const fbConfig = await Config.getFirebaseConfig();

  const firebaseConfig = {
    apiKey: fbConfig.apiKey,
    authDomain: fbConfig.authDomain,
    projectId: fbConfig.projectId,
    storageBucket: fbConfig.storageBucket,
    messagingSenderId: fbConfig.messagingSenderId,
    appId: fbConfig.appId
  };

  if(instance==null){
    instance = {
      firebase:firebase.initializeApp(firebaseConfig),
      auth:firebase.auth()
    };
  }
  return instance ;
};

// const Firebase = firebase.initializeApp(firebaseConfig);
// export const auth = firebase.auth();
// export default Firebase;
